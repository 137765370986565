<template>
  <form v-on:submit.prevent="submit">
    <slot></slot>
    <slot name="button">
      <div class="grid grid-cols-2 gap-4">
        <div
          class="px-4 py-3 mt-8 text-yellow-800 bg-yellow-100 rounded-md"
          role="alert"
          v-if="isInvalid"
        >
          {{ invalidMessage }}
        </div>
        <div v-else></div>
        <div class="pt-8 text-right">
          <submit-button :labels="buttonLabels" :buttonState="buttonState" />
        </div>
      </div>
    </slot>
  </form>
</template>

<script>
export default {
  name: 'ModelForm',
  props: {
    model: { type: Object },
    modelName: { type: String, default: 'model' },
    i18nScope: { type: String },
    validate: { type: Function, default: () => true },
    onSubmit: { type: Function, default: () => {} },
  },
  provide() {
    const self = this;
    return {
      parentModel() {
        return self.model;
      },
      parentI18nScope: this.i18nScope,
      modelName: this.modelName,
    };
  },
  data() {
    return {
      isInvalid: false,
      buttonState: 'default',
    };
  },
  computed: {
    isNew() {
      return !this.model.id;
    },
    invalidMessage() {
      return this.$t(`${this.i18nScope}.invalid`);
    },
    createButtonLabels() {
      return this.$t(`${this.i18nScope}.submit.create`);
    },
    updateButtonLabels() {
      return this.$t(`${this.i18nScope}.submit.update`);
    },
    buttonLabels() {
      return this.isNew ? this.createButtonLabels : this.updateButtonLabels;
    },
  },
  methods: {
    submit() {
      // special case: we don't want that pressing the enter key on inputs
      // triggers the submit function
      if (this.onSubmit === null) return;

      if (this.validate(this.model)) {
        this.buttonState = 'inProgress';
        this.isInvalid = false;
        this.onSubmit()
          .then(() => (this.buttonState = 'success'))
          .catch(() => (this.buttonState = 'fail'));
      } else {
        this.isInvalid = true;
      }
    },
  },
};
</script>
