var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"flex items-center py-5",class:{
    'text-gray-500': !_vm.libraryItem.published,
    'text-gray-900': _vm.libraryItem.published,
  },attrs:{"to":{
    name: 'editMedicalReportLibraryItem',
    params: { topicName: _vm.topic.name, libraryItemId: _vm.libraryItem.id },
  },"tag":"div"}},[_c('h4',{class:{
      italic: !_vm.libraryItem.published,
    }},[_vm._v(" "+_vm._s(_vm.title)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }