import Vue from 'vue';
import services from '@/services';
import { truncate, formatTimestamp, formatDate } from '@/shared/utils';

Vue.filter('agentFullName', services.agent.getFullName);
Vue.filter('agentFullNameInSentence', services.agent.getFullNameInSentence);
Vue.filter('agentTitle', services.agent.getTitle);
Vue.filter('truncate', truncate);
Vue.filter('formatTimestamp', formatTimestamp);
Vue.filter('formatDate', formatDate);
