import { track as trackAgent } from './agent-tracking';
import { getDB } from '@/shared/db';
import { SLACK_WEBHOOK_URL } from '@/shared/config';

export const isValid = () => {
  return true;
};

export const find = () => {
  return getDB()
    .collection('configs')
    .doc('dynamic');
};

export const applyChanges = (currentConfig, changes) => {
  const config = { ...currentConfig, ...changes };
  if (changes.almostDrowning !== undefined && currentConfig.drowning)
    config.drowning = !changes.almostDrowning;
  if (changes.drowning !== undefined && currentConfig.almostDrowning)
    config.almostDrowning = !changes.drowning;
  return config;
};

export const update = ({ almostDrowning, drowning }) => {
  trackAgent('UPDATE', 'CONFIG');

  const changes = {
    drowning,
    almostDrowning,
    ...almostDrowningAttributes(almostDrowning),
  };

  return find()
    .update(changes)
    .then(() => sendMessage(almostDrowning, drowning));
};

const almostDrowningAttributes = almostDrowning => {
  return almostDrowning
    ? { waitingTime: { min: 1800, max: 2700 } }
    : { waitingTime: { min: 600, max: 1200 } };
};

const sendMessage = (almostDrowning, drowning) => {
  if (!almostDrowning && !drowning)
    return sendSlackNotification(
      `Almost drowning and drowning modes have been set to disabled`
    );

  const mode = almostDrowning ? 'Almost drowning' : 'Drowning';
  return sendSlackNotification(`${mode} mode has been set to enabled`);
};

const sendSlackNotification = message => {
  return fetch(SLACK_WEBHOOK_URL, {
    method: 'POST',
    body: JSON.stringify({ text: message }),
  });
};
