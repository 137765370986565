import Welcome from '@/components/welcome';
import AgentSidebar from '@/components/admin/agents/sidebar';
import NewAgent from '@/components/admin/agents/new';
import EditAgent from '@/components/admin/agents/edit';

export default [
  {
    path: 'agents',
    name: 'agents',
    components: {
      default: Welcome,
      sidebar: AgentSidebar,
    },
    meta: {
      protected: true,
      sidebar: true,
      policy: { scope: 'agent', action: 'show' },
    },
  },
  {
    path: 'agents/new',
    name: 'newAgent',
    components: {
      default: NewAgent,
      sidebar: AgentSidebar,
    },
    props: {
      sidebar: true,
      default: true,
    },
    meta: {
      protected: true,
      sidebar: true,
      policy: { scope: 'agent', action: 'show' },
    },
  },
  {
    path: 'agents/:agentId/edit',
    name: 'editAgent',
    components: {
      default: EditAgent,
      sidebar: AgentSidebar,
    },
    props: {
      sidebar: true,
      default: true,
    },
    meta: {
      protected: true,
      sidebar: true,
      policy: { scope: 'agent', action: 'show' },
    },
  },
];
