import Vue from 'vue';
import './form';

import Avatar from './avatar';
import Dropdown from './dropdown';
import Loader from './loader';
import DotLoader from './dot-loader';
import TextareaAutosize from './textarea-autosize';
import DestroyButton from './destroy-button';
import Tabs from './tabs';
import Tab from './tab';
import InfiniteScrolling from './infinite-scrolling';
import Tag from './tag';
import Rating from './rating';
import AgeRangeSlider from './age-range-slider';
import CustomSwitch from './custom-switch';
import SubmitButton from './submit-button';
import ConfirmSubmitButton from './confirm-submit-button';
import SeverityBadge from './severity-badge';
import AgentPicker from './agent-picker';
import LocalePicker from './locale-picker';
import DatePicker from './date-picker';

Vue.component('avatar', Avatar);
Vue.component('agent-picker', AgentPicker);
Vue.component('dropdown', Dropdown);
Vue.component('submit-button', SubmitButton);
Vue.component('confirm-submit-button', ConfirmSubmitButton);
Vue.component('destroy-button', DestroyButton);
Vue.component('textarea-autosize', TextareaAutosize);
Vue.component('tabs', Tabs);
Vue.component('tab', Tab);
Vue.component('loader', Loader);
Vue.component('dot-loader', DotLoader);
Vue.component('infinite-scrolling', InfiniteScrolling);
Vue.component('tag', Tag);
Vue.component('rating', Rating);
Vue.component('custom-switch', CustomSwitch);
Vue.component('age-range-slider', AgeRangeSlider);
Vue.component('severity-badge', SeverityBadge);
Vue.component('locale-picker', LocalePicker);
Vue.component('date-picker', DatePicker);
