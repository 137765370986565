<template>
  <sidebar-layout>
    <template v-slot:title>
      <font-awesome-icon :icon="['fad', 'book-medical']" class="mr-3" />
      <span>{{ $t('admin.medicalReportLibrary.list.title') }}</span>
    </template>

    <template v-slot:actions>
      <router-link
        :to="{
          name: 'newMedicalReportLibraryItem',
          params: { topicName: topic.name },
        }"
        class="btn-sm btn-outline-primary"
        v-if="topic"
      >
        <font-awesome-icon :icon="['fas', 'plus']" />
      </router-link>
    </template>

    <div class="p-2 space-y-2 border-b border-gray-100">
      <search-input @search="onSearch" />
      <div class="flex justify-between items-center">
        <label class="text-gray-700" for="show-unpublished-mr-items">
          {{ $t('admin.medicalReportLibrary.list.showUnpublished') }}
        </label>
        <custom-switch
          name="show-unpublished-mr-items"
          @input="onShowUnpublished"
        />
      </div>
    </div>

    <library-topic
      v-for="localTopic in topics"
      :key="localTopic.name"
      :topic="localTopic"
      :active="topic && localTopic.name === topic.name"
      :libraryItemId="localTopic.name"
      :filters="filters"
      @select="setTopic"
      @show-topic="onShowTopic"
    />
    <div v-if="areAllTopicsHidden" class="px-4 py-5 font-semibold">
      {{ $t('admin.savedReplies.list.noSavedReply') }}
    </div>
  </sidebar-layout>
</template>

<script>
import MedicalReportLibraryMixin from '@/mixins/medical-report-library';
import { arrayItemsEquals } from '@/shared/utils';

import SidebarLayout from '@/components/admin/shared/sidebar-layout';
import LibraryTopic from './tree/topic';
import CustomSwitch from '../../global/custom-switch.vue';

export default {
  name: 'MedicalReportingLibrarySidebar',
  mixins: [MedicalReportLibraryMixin],
  components: { SidebarLayout, LibraryTopic, CustomSwitch },
  props: ['libraryItemId'],
  data() {
    return {
      topic: null,
      query: null,
      showUnpublished: false,
      hiddenTopics: [],
    };
  },
  computed: {
    topics() {
      return this.services.medicalReportLibrary.findAllTopics(
        this.contentLocale,
        true
      );
    },
    filters() {
      return {
        query: this.query,
        showUnpublished: this.showUnpublished,
      };
    },
    areAllTopicsHidden() {
      return arrayItemsEquals(
        this.hiddenTopics,
        this.topics.map(topic => topic.name)
      );
    },
  },
  methods: {
    setTopic(topic) {
      this.topic === topic ? (this.topic = null) : (this.topic = topic);
    },
    onSearch(query) {
      this.query = query;
      this.topic = null;
    },
    onShowUnpublished(value) {
      this.showUnpublished = value;
    },
    onShowTopic(topic, show) {
      if (show && this.hiddenTopics.includes(topic)) {
        this.hiddenTopics = this.hiddenTopics.filter(
          hiddenTopic => hiddenTopic !== topic
        );
      } else if (!show) this.hiddenTopics.push(topic);
    },
  },
};
</script>
