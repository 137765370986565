<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
import merge from 'deepmerge';

export default {
  name: 'ArrayItemNestedModelForm',
  props: {
    value: { type: Array, default: () => [] },
    model: { type: Object, required: true },
    modelName: { type: String, default: 'array-item-nested-model' },
    i18nScope: { type: String },
  },
  provide() {
    const self = this;
    return {
      parentModel() {
        return self.model;
      },
      parentI18nScope: this.i18nScope,
      modelName: `${this.modelName}-${this.model.id}`,
    };
  },
  created() {
    this.$on('change', changes => this.onChange(changes));
  },
  methods: {
    onChange(changes) {
      const updatedModel = merge.all([{}, this.model, changes]);
      const newValue = this.value.slice();
      const index = this.value.findIndex(model => model.id === this.model.id);
      newValue[index] = updatedModel;
      this.$emit('input', newValue);
    },
  },
};
</script>
