<template>
  <model-form
    :model="brandedDrug"
    i18nScope="admin.brandedDrugs.form"
    :validate="services.brandedDrug.isValid"
    :on-submit="persist"
    v-on="$listeners"
  >
    <text-input attributeName="name" :max-characters="50" />

    <div class="grid grid-cols-2 gap-4 mt-6">
      <text-input attributeName="shortLabel" />
      <text-input attributeName="mobileLabel" />
    </div>

    <molecules-input attributeName="molecules" class="mt-6" />

    <hr class="my-6" />

    <nested-model-form
      v-model="brandedDrug.filters"
      i18nScope="admin.brandedDrugs.form.filters"
    >
      <agent-group-input attributeName="agentGroupIds" class="mb-6" />

      <div class="grid grid-cols-12 gap-4 mt-6 mb-8">
        <gender-input attributeName="gender" class="col-span-3" />
<!--        <age-range-input attributeName="age" class="col-span-9 h-100" />-->
      </div>
    </nested-model-form>
  </model-form>
</template>

<script>
import MoleculesInput from './molecules-input';

export default {
  name: 'BrandedDrugForm',
  components: { MoleculesInput },
  props: {
    brandedDrug: { type: Object },
    persist: { type: Function, default: () => {} },
  },
};
</script>
