<template>
  <dropdown
    menuAlign="right"
    color="primary"
    :buttonCustomClass="buttonClass"
    :border="false"
    :size="buttonSize"
    :dropup="dropup"
    :disabled="isInProgressState"
    ref="dropdown"
  >
    <template v-slot:button>
      <span v-if="isDefaultState" data-button-label>{{ defaultLabel }}</span>

      <span
        v-if="isInProgressState"
        class="d-flex align-items-center justify-content-center"
      >
        <font-awesome-icon :icon="['fal', 'circle-notch']" spin />
        <span class="ml-3" data-button-label>{{ inProgressLabel }}</span>
      </span>

      <span
        v-if="isSuccessState"
        class="d-flex align-items-center justify-content-center"
      >
        <font-awesome-icon :icon="['fas', 'check']" />
        <span class="ml-3" data-button-label>{{ successLabel }}</span>
      </span>

      <span
        v-if="isFailState"
        class="d-flex align-items-center justify-content-center"
      >
        <font-awesome-icon :icon="['fas', 'exclamation']" />
        <span class="ml-3" data-button-label>{{ failLabel }}</span>
      </span>
    </template>

    <div class="mx-5 my-3">
      <p>
        {{ confirmationLabels.text }}
      </p>
      <div class="d-flex justify-content-between">
        <button
          v-on:click.stop.prevent="closeDropdown"
          class="btn btn-outline-secondary btn-sm"
        >
          <span class="d-flex align-items-center">
            <font-awesome-icon :icon="['fal', 'times-square']" />
            <span class="ml-3">{{ confirmationLabels.cancel }}</span>
          </span>
        </button>

        <button
          v-on:click.stop.prevent="submit"
          class="btn btn-outline-secondary btn-sm"
        >
          <span class="d-flex align-items-center">
            <font-awesome-icon :icon="['fal', 'check-square']" />
            <span class="ml-3">{{ confirmationLabels.ok }}</span>
          </span>
        </button>
      </div>
    </div>
  </dropdown>
</template>

<script>
import SubmitButtonMixin from '@/mixins/submit-button';

export default {
  name: 'ConfirmSubmitButton',
  mixins: [SubmitButtonMixin],
  props: {
    buttonSize: { type: String, required: false, default: null },
    dropup: { type: Boolean, required: false, default: false },
  },
  computed: {
    confirmationLabels() {
      return (
        this.labels.confirmation || { text: 'Test', ok: 'OK', cancel: 'Cancel' }
      );
    },
  },
  methods: {
    submit() {
      this.$emit('click');
    },
    closeDropdown() {
      this.$refs.dropdown.closeDropdown();
    },
  },
  watch: {
    isInProgressState(value) {
      if (value) this.closeDropdown();
    },
  },
};
</script>
