<template>
  <div class="form-group">
    <div class="flex items-center justify-between">
      <label :for="name" v-if="!withoutLabel">{{ label }}</label>
      <div class="relative checkbox-input flex items-center">
        <input
          type="checkbox"
          class="sr-only"
          :id="name"
          :name="name"
          v-model="value"
        />
        <label :for="name">
          <div
            class="block bg-gray-100 w-8 h-5 rounded-full transition-colors duration-300 cursor-pointer"
          ></div>
          <div
            class="dot absolute left-1 top-1 bg-gray-300 w-3 h-3 rounded-full transition-colors duration-300 cursor-pointer"
          ></div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import ModelInputMixin from '@/mixins/model-input';

export default {
  name: 'CheckboxInput',
  mixins: [ModelInputMixin],
};
</script>
