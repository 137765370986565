<template>
  <div class="form-group mb-1">
    <label for="saved-reply-groups">{{
      $t('admin.savedReplies.form.agentGroups.label')
    }}</label>
    <multiselect
      v-model="agentGroupIdsInput"
      :multiple="true"
      :options="agentGroups"
      track-by="id"
      label="name"
      v-if="agentGroups"
      :placeholder="t('placeholder')"
      :selectedLabel="t('selectedLabel')"
      :selectLabel="t('selectLabel')"
      :deselectLabel="t('deselectLabel')"
    >
    </multiselect>
  </div>
</template>

<script>
export default {
  name: 'SavedReplyGroupForm',
  props: {
    savedReply: { type: Object },
  },
  data() {
    return {
      agentGroups: null,
    };
  },
  mounted() {
    this.$bind('agentGroups', this.services.agentGroup.findAll());
  },
  computed: {
    agentGroupIdsInput: {
      get() {
        return (this.savedReply.agentGroupIds || [])
          .map(id => this.agentGroups.find(group => group.id === id))
          .filter(group => group);
      },
      set(value) {
        // NOTE: the 'all' group is required to list all the saved replies with no groups
        const agentGroupIds =
          value.length > 0 ? value.map(group => group.id) : ['all'];
        this.$emit('change', { agentGroupIds });
      },
    },
  },
  methods: {
    t(name) {
      return this.$t(`admin.savedReplies.form.agentGroups.multiselect.${name}`);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
