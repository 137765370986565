<template>
  <div>
    <div
      v-for="molecule in list"
      :key="molecule.id"
      class="list-group-item cursor-pointer"
      :class="{
        'hover:bg-gray-50': molecule.id !== moleculeId,
        'border-l-2 border-blue-700 bg-indigo-50 hover:bg-indigo-100':
          molecule.id === moleculeId,
      }"
    >
      <div class="cursor-pointer border-b border-gray-100 px-4">
        <molecule :molecule="molecule" />
      </div>
    </div>
  </div>
</template>

<script>
import Molecule from './molecule';

export default {
  name: 'MoleculeList',
  components: { Molecule },
  props: {
    list: { type: Array, default: null },
    moleculeId: { type: String, default: null },
  },
};
</script>
