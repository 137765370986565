import EditConfig from '@/components/admin/config/edit';

export default [
  {
    path: 'config',
    name: 'config',
    component: EditConfig,
    meta: {
      protected: true,
      policy: { scope: 'config', action: 'show' },
    },
  },
];
