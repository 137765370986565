export default {
  props: {
    type: { type: String, default: 'submit' },
    labels: { type: Object, default: undefined },
    buttonState: { type: String, default: 'default' },
    miscButtonClass: { type: String, default: '' },
  },
  data() {
    return {
      internalButtonState: 'default',
      timeout: undefined,
    };
  },
  computed: {
    isDefaultState() {
      return this.internalButtonState === 'default';
    },
    defaultLabel() {
      return this.labels?.default || this.$i18n.t('submitButton.default');
    },
    isInProgressState() {
      return this.internalButtonState === 'inProgress';
    },
    inProgressLabel() {
      return this.labels?.inProgress || this.$i18n.t('submitButton.inProgress');
    },
    isSuccessState() {
      return this.internalButtonState === 'success';
    },
    successLabel() {
      return this.labels?.success || this.$i18n.t('submitButton.success');
    },
    isFailState() {
      return this.internalButtonState === 'fail';
    },
    failLabel() {
      return this.labels?.fail || this.$i18n.t('submitButton.fail');
    },
    buttonClass() {
      switch (this.internalButtonState) {
        case 'default':
        case 'inProgress':
          return 'btn-primary';
        case 'success':
          return 'btn-success';
        case 'fail':
          return 'btn-warning';
      }
      return 'btn-primary';
    },
  },
  watch: {
    buttonState: {
      immediate: true,
      handler(newValue) {
        this.internalButtonState = newValue;
        if (newValue === 'success' || newValue === 'fail') {
          if (this.timeout) clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            this.internalButtonState = 'default';
          }, 3000);
        }
      },
    },
  },
};
