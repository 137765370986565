<template>
  <div>
    <div class="flex items-center py-5 text-gray-800">
      <router-link
        :to="{
          name: 'editBrandedDrug',
          params: { brandedDrugId: brandedDrug.id },
        }"
        tag="div"
        class="cursor-pointer"
      >
        <h3 class="">
          {{ brandedDrug.shortLabel }}
        </h3>
        <p class="text-gray-400">{{ moleculeNames }}</p>
      </router-link>
      <button
        class="btn btn-link text-dark cursor-pointer ml-auto"
        @click="toggleDrugList"
        role="expandBrandedDrug"
      >
        <font-awesome-icon
          :icon="['fal', drugListExpanded ? 'chevron-up' : 'chevron-down']"
        />
      </button>
    </div>

    <collapse-transition>
      <drug-list
        :key="drugListKey"
        :brandedDrug="brandedDrug"
        class="pt-2 pb-6 px-4"
        v-if="drugListExpanded"
      />
    </collapse-transition>
  </div>
</template>

<script>
import DrugList from './drug-list';

export default {
  name: 'BrandedDrugListItem',
  components: { DrugList },
  props: {
    brandedDrug: { type: Object },
    drugId: { type: String, default: null },
    active: { type: Boolean, default: false },
  },
  data() {
    return {
      drugListExpanded: false,
    };
  },
  mounted() {
    this.drugListExpanded = this.active;
  },
  computed: {
    drugListKey() {
      return this.buildCompositeKey(
        this.brandedDrug.id,
        this.brandedDrug.drugs?.length || 0
      );
    },
    moleculeNames() {
      return this.brandedDrug.molecules
        .map(molecule => molecule.name)
        .join(', ');
    },
  },
  methods: {
    toggleDrugList() {
      this.drugListExpanded = !this.drugListExpanded;
    },
  },
};
</script>
