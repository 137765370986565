<template>
  <div class="flex items-center justify-center h-full w-full">
    <div class="text-center">
      <avatar :user="agent" class="h-16 w-16 mx-auto" />

      <h6 class="font-bold mt-5">
        {{ $t('welcome.title', { name: getAgentFullName }) }}
      </h6>
      <p class="text-gray-500 mt-1">
        {{ $t('welcome.subTitle') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'Welcome',
  computed: {
    ...mapState(['agent']),
    ...mapGetters(['getAgentFullName']),
  },
};
</script>
