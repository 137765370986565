import moment from 'moment';
import i18n from '@/plugins/i18n.js';
import TLDs from '@/shared/tlds';
import * as uuid from 'uuid';

const ACCENTS = {
  a: new RegExp('á|à|ã|â|À|Á|Ã|Â', 'g'),
  e: new RegExp('é|è|ê|É|È|Ê', 'g'),
  i: new RegExp('í|ì|î|Í|Ì|Î', 'g'),
  o: new RegExp('ó|ò|ô|õ|Ó|Ò|Ô|Õ', 'g'),
  u: new RegExp('ú|ù|û|ü|Ú|Ù|Û|Ü', 'g'),
  c: new RegExp('ç|Ç', 'g'),
  n: new RegExp('ñ|Ñ', 'g'),
};

export const isBlank = object => {
  return (
    object === undefined ||
    object === null ||
    (typeof object === 'string' && object.length === 0) ||
    (typeof object === 'object' && object.length === 0)
  );
};

export const isObjectEmpty = obj => {
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
  }
  return true;
};

export const isArraySubset = (array1, array2) => {
  if (!array1 || !array2) return false;
  return array1.some(item => array2.includes(item));
};

export const arrayItemsEquals = (array1, array2) => {
  if (!array1 || !array2) return false;

  return (
    array1.length === array2.length &&
    array1.every(value => array2.includes(value))
  );
};

export const nowInUTC = () => {
  return moment.utc(new Date()).toDate();
};

export const startOfDay = date => {
  return moment(date)
    .startOf('day')
    .toDate();
};

export const endOfDay = date => {
  return moment(date)
    .endOf('day')
    .toDate();
};

export const addDaysToNow = days => {
  return moment
    .utc(new Date())
    .add(days, 'days')
    .startOf('day')
    .toDate();
};

export const daysBetweenDates = (date1, date2) => {
  return moment
    .utc(startOfDay(date1))
    .diff(moment.utc(startOfDay(date2 || new Date())), 'days');
};

export const timeUntilNextMorning = now => {
  now = new moment(now);
  const nextMorning = new moment(now)
    .startOf('day')
    .add(1, 'hours')
    .add(1, 'days');
  const duration = moment.duration(nextMorning.diff(now));
  return duration.asMilliseconds();
};

export const isToday = date => {
  return isSameDay(date, moment().toDate());
};

export const isSameDay = (date1, date2) => {
  return startOfDay(date1).getTime() === startOfDay(date2).getTime();
};

export const isSameFirebaseTimestamp = (timestamp1, timestamp2) => {
  return timestamp1.toDate().getTime() === timestamp2.toDate().getTime();
};

export const formatDate = (date, format) => {
  return moment(date).format(i18n.t(`dates.${format || 'default'}`));
};

export const formatTimestamp = timestamp => {
  let date = moment();
  if (timestamp)
    date = moment(timestamp.toDate ? timestamp.toDate() : timestamp);
  return date.format(i18n.t('times.short')).replace('_', 'h'); // NOTE: only way to get something like 3h42 in French
};

export const formatDateFromString = string => {
  return formatDate(parseAPITimestamp(string));
};

export const parseURLDate = date => {
  return moment(date, 'YYYY-MM-DD')
    .endOf('day')
    .toDate();
};

export const toAPIDate = date => {
  if (isBlank(date)) return null;
  return moment(date).format('YYYY-MM-DD');
};

export const toAPITimestamp = date => {
  if (isBlank(date)) return null;
  return moment(date).toISOString();
};

export const parseAPITimestamp = date => {
  return parseAPITimestampToMoment(date).toDate();
};

export const parseAPITimestampToMoment = date => {
  return moment.parseZone(date);
};

export const getKidAgeInMonths = date => {
  return moment().diff(date.toDate(), 'months');
};

// gender: 0 (girl) | 1 (boy)
export const kidAgeFromBirthday = (date, gender, today, shortFormat) => {
  if (date === undefined || date === null) return null;
  if (today === undefined) today = moment();
  else if (typeof today === 'string') today = parseAPITimestampToMoment(today);
  else today = moment(today.toDate());
  const days = today.diff(date.toDate(), 'days');
  const weeks = today.diff(date.toDate(), 'weeks');
  const months = today.diff(date.toDate(), 'months');
  const years = today.diff(date.toDate(), 'years');
  const scope = shortFormat ? 'shortFormatBirthday' : 'birthday';

  // console.log(date.toDate(), days, weeks, months, years);

  if (days === 0) return i18n.t(`${scope}.today.${gender}`);
  else if (days < 15) return i18n.t(`${scope}.days`, { days });
  else if (months < 3)
    return i18n.tc(`${scope}.weeks`, days - weeks * 7, { weeks });
  else if (years < 2)
    return i18n.tc(
      `${scope}.months`,
      Math.trunc((days - months * 30.4167) / 7),
      { months }
    );
  else return i18n.tc(`${scope}.years`, months - years * 12, { years });
};

export const getFileExtension = filename => {
  return /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : undefined;
};

export const uuidv4 = () => {
  return uuid.v4();
};

export const hashCode = string => {
  return string.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
};

export const parameterize = string => {
  return string
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/, '')
    .replace(/\s/g, '-');
};

export const truncate = (value, limit) => {
  if (value.length > limit) {
    value = value.substring(0, limit - 3) + '...';
  }
  return value;
};

export const playSound = () => {
  const sound = new Audio(require('@/assets/notification.wav'));
  return sound.play().catch(error => {
    console.log("WARNING: can't play a sound, reason: ", error);
  });
};

export const parseAjaxResponse = (response, codes) => {
  if (typeof codes !== 'object') codes = [codes];
  if (codes.indexOf(response.status) === -1) throw Error(response.message);
  let item = response.data;
  if (typeof item !== 'object' || item.error) {
    item = undefined;
  }
  return item;
};

export const debounce = (fn, time) => {
  let timeoutId;
  function wrapper(...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  }
  return wrapper;
};

export const interpolate = (text, interpolations) => {
  var newText = text;
  for (let [key, value] of Object.entries(interpolations)) {
    let regexp = new RegExp(`\\{\\s*${key}\\s*\\}`, 'g');
    newText = newText.replace(regexp, value);
  }
  return newText;
};

export const htmlToMobileContent = html => {
  var parser = new DOMParser();
  var doc = parser.parseFromString(
    html.replace(/^-\s*/gim, '•  '),
    'text/html'
  );
  var body = doc.body;
  var content = '';

  for (var i = 0; i < body.childNodes.length; i++) {
    const element = body.childNodes[i];
    switch (element.nodeName) {
      case 'P':
        content = `${content}${htmlToMobileContent(element.innerHTML)}\n`;
        break;

      case 'A':
        content = `${content}${element.textContent} (${element.href})`;
        break;

      case '#text':
        content = `${content}${element.textContent}`;
        break;

      case 'UL':
        element.childNodes.forEach(childElement => {
          if (childElement.nodeName === 'LI')
            content = `${content}•  ${childElement.textContent}\n`;
        });
        break;

      case 'BR':
        content = `${content}\n`;
        break;

      default:
        break;
    }
  }
  return content.trim();
};

export const removeAccents = text => {
  for (let [letter, regexp] of Object.entries(ACCENTS)) {
    text = text.replace(regexp, letter);
  }
  return text;
};

export const replaceEmojis = text => {
  return text
    .replace(/\p{Extended_Pictographic}/gu, '<span class="emoji">$&</span>')
    .replace('🏻', '') // fitzpatrick-2
    .replace('🏼', '') // fitzpatrick-3
    .replace('🏽', '') // fitzpatrick-4
    .replace('🏾', '') // fitzpatrick-5
    .replace('🏿', ''); // fitzpatrick-6
};

export const replaceURLs = text => {
  var regexp = /((https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.+\d~#?&//=]*)\b)/gm;
  var firstUrl = undefined;

  var modifiedText = text.replace(regexp, href => {
    const isMail = href.indexOf('@') !== -1; // NOTE: not very reliable but will work for most of the cases;

    if (isMail) {
      return `<a href="mailto:${href}">${href}</a>`;
    } else {
      const urlString =
        href.startsWith('http://') || href.startsWith('https://')
          ? href
          : `https://${href}`;
      var url = null;

      // defensive code because the Regexp is not bullet proof
      try {
        url = new URL(urlString);
      } catch {
        return href;
      }
      var tld = url.origin
        .split('.')
        .pop()
        .toUpperCase();

      if (TLDs.includes(tld)) {
        if (!firstUrl) firstUrl = href;
        return `<a href="${url.href}" target="_blank">${href}</a>`;
      }
    }
    return href;
  });

  return { text: modifiedText, url: firstUrl };
};

// Depending on the value of the order parameter, it will return the 2 following structures:
// - { <attribute 1>: [EL1, EL2, ..., ELN], ..., <attribute n>: [ELA, ELB..., ELN'] } (without order)
// - [[<attribute 1>, [EL1, EL2, ..., ELN]], ..., [<attribute n>, [ELA, ELB..., ELN']]]
export const groupBy = (array, attribute, order) => {
  var hash = {};

  array.forEach(element => {
    let group = (hash[element[attribute]] = hash[element[attribute]] || []);
    group.push(element);
  });

  if (order === undefined) return hash;

  return Object.keys(hash)
    .sort()
    .map(key => [key, hash[key]]);
};

export const getSupportedLanguage = language => {
  switch (language) {
    case undefined:
    case null:
    case '':
    case 'fr':
      return 'fr';
    default:
      return 'en';
  }
};

export const getFiletoBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const sendHttpRequest = obj => {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open(obj.method || 'GET', obj.url);
    if (obj.headers) {
      Object.keys(obj.headers).forEach(key => {
        xhr.setRequestHeader(key, obj.headers[key]);
      });
    }
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject(xhr.statusText);
      }
    };
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send(obj.body);
  });
};

// https://forum.vuejs.org/t/generating-computed-properties-on-the-fly/14833/7
// https://jsfiddle.net/Linusborg/3p4kpz1t/
export const mapPropsModels = (props = [], { object, event } = {}) => {
  return props.reduce((obj, prop) => {
    const propModel = `${prop}Input`;
    const computedProp = {
      get() {
        return object ? this[object][prop] : this[prop];
      },
      set(value) {
        if (event) {
          this.$emit(event, { prop, value });
        } else {
          this[object] = { ...this[object], [prop]: value };
        }
      },
    };
    obj[propModel] = computedProp;
    return obj;
  }, {});
};

// Like pick of Lodash but without the Lodash dependency
export const pick = (obj, ...args) => ({
  ...args.reduce((res, key) => ({ ...res, [key]: obj[key] }), {}),
});

export const asyncSome = (array, callback) => {
  return new Promise(resolve => {
    let promises = (array || []).map(async el => {
      if (await callback(el)) {
        resolve(true);
      }
    });
    Promise.all(promises).then(() => resolve(false));
  });
};

export const parseJwt = token => {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
};
