<template>
  <router-link
    :to="{ name: 'editAgentGroup', params: { agentGroupId: agentGroup.id } }"
    tag="div"
    class="flex items-center py-5 text-gray-800"
  >
    <font-awesome-icon
      :icon="['fal', 'computer-classic']"
      class="text-gray-900 cursor-move mr-3"
      v-if="agentGroup.system"
    />
    <h3>
      {{ agentGroup.name }}
    </h3>
    <span
      class="bg-blue-600 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs ml-auto"
      >{{ agentGroup.agentIds.length }}</span
    >
  </router-link>
</template>

<script>
export default {
  name: 'AgentGroupListItem',
  props: {
    agentGroup: { type: Object },
  },
};
</script>
