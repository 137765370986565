<template>
  <v-date-picker
    v-model="date"
    :locale="uiLocale"
    :popover="{ placement: 'bottom', visibility: 'click' }"
    :min-date="minDate"
    :max-date="maxDate"
  >
    <button :class="finalButtonClass">
      <span v-if="date">{{ formattedDate }}</span>
      <span v-else>{{ placeholder }}</span>
    </button>
  </v-date-picker>
</template>

<script>
import { formatDate, endOfDay } from '@/shared/utils';

export default {
  name: 'Datepicker',
  props: {
    value: { type: Date, default: null },
    minDate: { type: Date, default: null },
    maxDate: {
      type: Date,
      default: () => {
        new Date();
      },
    },
    buttonClass: { default: null },
    placeholder: { type: String, default: 'no selected date' },
  },
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(date) {
        this.$emit('input', endOfDay(date));
      },
    },
    formattedDate() {
      return formatDate(this.date);
    },
    finalButtonClass() {
      return (
        this.buttonClass ||
        'btn btn-outline-secondary btn-md font-medium text-dark'
      );
    },
  },
};
</script>
