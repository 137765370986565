import Vue from 'vue';
import HoneybadgerVue from '@honeybadger-io/vue';
import { HONEYBADGER_API_KEY, APP_REVISION } from '../shared/config';

if (process.env.NODE_ENV === 'production') {
  const config = {
    apiKey: HONEYBADGER_API_KEY,
    environment: process.env.NODE_ENV,
    revision: APP_REVISION,
  };

  Vue.use(HoneybadgerVue, config);
}

export default {
  setContext(context) {
    if (process.env.NODE_ENV === 'production')
      Vue.$honeybadger.setContext(context);
  },
};
