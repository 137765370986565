<template>
  <main-layout>
    <template v-slot:title>
      {{ $t('admin.brandedDrugs.edit.title') }}
    </template>

    <branded-drug-form
      class="py-4 px-4"
      :key="brandedDrug.id"
      :brandedDrug="brandedDrug"
      :persist="onPersist"
      @change="onChange"
      v-if="brandedDrug"
    />
  </main-layout>
</template>

<script>
import { mapActions } from 'vuex';
import merge from 'deepmerge';
import MainLayout from '@/components/admin/shared/main-layout';
import BrandedDrugForm from './form';

export default {
  name: 'EditBrandedDrug',
  components: { MainLayout, BrandedDrugForm },
  props: {
    brandedDrugId: { type: String },
  },
  data() {
    return {
      brandedDrug: null,
    };
  },
  created() {
    this.$watch(
      vm => (vm.brandedDrugs, vm.brandedDrugId, Date.now()),
      () => this.fetchBrandedDrug(),
      { immediate: true }
    );
  },
  methods: {
    ...mapActions(['updateBrandedDrug']),
    onChange(changes) {
      const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
      this.brandedDrug = merge.all(
        [this.brandedDrug, changes, { id: this.brandedDrugId }],
        { arrayMerge: overwriteMerge }
      );
    },
    onPersist() {
      return this.updateBrandedDrug(this.brandedDrug);
    },
    async fetchBrandedDrug() {
      this.brandedDrug = await this.services.brandedDrug.find(
        this.brandedDrugId,
        true
      );
      // the branded drug doesn't exist
      if (!this.brandedDrug) this.$router.push({ name: 'brandedDrugs' });
    },
  },
};
</script>
