<template>
  <div>
    <div
      class="bg-black text-white rounded-full h-full w-full flex items-center justify-center"
      v-if="!hasPicture"
    >
      <span>{{ getInitials }}</span>
    </div>

    <img
      class="bg-black rounded-full h-full w-full"
      :src="picture"
      @error="onError"
      v-else
    />
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    user: { type: Object, default: undefined },
    online: { type: Boolean, default: undefined },
    onlyFirstName: { type: Boolean, default: false },
  },
  data() {
    return { cantLoadPicture: false };
  },
  computed: {
    getInitials() {
      if (
        this.user?.firstName === undefined ||
        this.user.firstName.length === 0
      )
        return '??';

      const firstNameLetter = this.user.firstName.charAt(0).toUpperCase();
      const lastNameLetter = (
        this.user.lastName?.charAt(0) || ''
      ).toUpperCase();

      return this.onlyFirstName
        ? this.user.firstName.charAt(0)
        : `${firstNameLetter}${lastNameLetter}`;
    },
    hasPicture() {
      return (
        !this.cantLoadPicture &&
        this.picture !== undefined &&
        this.picture !== null &&
        this.picture !== ''
      );
    },
    picture() {
      return this.user?.profilePic;
    },
  },
  methods: {
    onError() {
      this.cantLoadPicture = true;
    },
  },
};
</script>
