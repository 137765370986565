<template>
  <div v-if="showTopic">
    <div
      class="cursor-pointer hover:bg-gray-50"
      :class="{
        'border-l-2 border-blue-700': active,
      }"
      @click="$emit('select', topic)"
    >
      <div class="flex items-center py-5 px-4 border-b border-gray-100">
        <font-awesome-icon :icon="topic.icon" fixed-width />
        <h3 class="ml-3" :class="{ 'font-bold': active }">
          {{ title }}
        </h3>
      </div>
    </div>

    <collapse-transition>
      <library-tree
        :libraryItemId="libraryItemId"
        :topic="topic"
        :list="filteredTree"
        v-if="open"
      />
    </collapse-transition>
  </div>
</template>

<script>
import LibraryTree from './index';

export default {
  name: 'MedicalReportLibraryTopic',
  components: { LibraryTree },
  props: ['topic', 'active', 'libraryItemId', 'filters'],
  data() {
    return {
      list: null,
    };
  },
  mounted() {
    this.$bind(
      'list',
      this.services.medicalReportLibrary.findAll(
        this.topic.name,
        this.contentLocale
      )
    );
  },
  computed: {
    title() {
      return this.topic.title[this.contentLocale];
    },
    isQuerying() {
      return !!this.filters.query && this.filters.query.length > 0;
    },
    open() {
      return (
        (this.active && this.list) || (this.isQuerying && this.tree.length > 0)
      );
    },
    tree() {
      return this.services.medicalReportLibrary.buildContentTree(
        this.list,
        this.contentLocale,
        this.filters
      );
    },
    filteredTree() {
      return this.isQuerying
        ? this.tree.filter(
            libraryItem => libraryItem.children.length > 0 || libraryItem.leaf
          )
        : this.tree;
    },
    showTopic() {
      return (
        !this.isQuerying || (this.isQuerying && this.filteredTree.length > 0)
      );
    },
  },
  watch: {
    showTopic() {
      this.$emit('show-topic', this.topic.name, this.showTopic);
    },
  },
};
</script>
