import Vue from 'vue';
import store from '@/store';

export const requireAuthentication = async (to, from, next) => {
  // At the very startup, don't ask for the email/password if already authenticated
  if (store.state.appLoading) await store.dispatch('localSignInAction');

  let { appLoading, authenticated } = store.state;

  if (to.name === 'signIn' && authenticated) {
    // NOTE: if the user asks for the signIn page and if she/he was previously signed in,
    // it's safe to redirect to the worspace. Before, seing the whole screen, she/he will have to
    // wait for the Firebase authentication and so the loader will be displayed.
    next({ name: 'dashboard' });
  } else if (appLoading) {
    // NOTE: at this point, we don't know for sure if the user is connected for real or not.
    // Display the loader instead and if the authentication fails down the road,
    // the user will be redirected to the signIn page.
    // However, whether the user is connected for real or not, we can still check
    // if she has the rights to access the view
    const policy = to.matched.find(record => record.meta.policy)?.meta?.policy;

    if (authenticated && policy && !Vue.policy(policy.scope, policy.action)) {
      next({ name: 'dashboard' });
    } else next();
  } else {
    const protectedURL = to.matched.some(record => record.meta.protected);
    const policy = to.matched.find(record => record.meta.policy)?.meta?.policy;

    if (protectedURL && !authenticated) {
      next({ name: 'signIn', query: { nextUrl: to.fullPath } });
    } else if (policy && !Vue.policy(policy.scope, policy.action)) {
      next({ name: 'dashboard' });
    } else {
      next();
    }
  }
};
