import merge from 'deepmerge';
import { uuidv4 } from '@/shared/utils';
import * as API from '@/shared/api';
import { track as trackAgent } from './agent-tracking';

export const build = attributes => {
  return merge(
    {
      name: '',
      shortLabel: '',
      mobileLabel: '',
      dosages: [],
    },
    attributes || {}
  );
};

export const buildDosage = attributes => {
  return merge(
    {
      id: uuidv4(),
      name: '',
      shortLabel: '',
      mobileLabel: '',
      isNew: true,
    },
    attributes || {}
  );
};

export const isValid = attributes => {
  return (
    !!attributes.name &&
    attributes.name.length > 0 &&
    !!attributes.dosages &&
    attributes.dosages.length > 0
  );
};

export const sortDosages = drug => {
  if (!drug?.dosages) return drug;
  drug.dosages = sortedDosages(drug);
  return drug;
};

export const sortedDosages = drug => {
  return (drug.dosages || []).sort((d1, d2) =>
    d1.shortLabel.localeCompare(d2.shortLabel)
  );
};

export const filteredDosages = drug => {
  return sortedDosages(drug).filter(dosage => dosage.deleted !== true);
};

export const getPermittedAttributes = ({ dosages, ...attributes }) => {
  return {
    ...attributes,
    dosages: dosages.map(dosage => {
      let permittedDosage = { ...dosage };
      if (permittedDosage.isNew) {
        delete permittedDosage.id;
        delete permittedDosage.isNew;
      }
      return permittedDosage;
    }),
  };
};

export const find = (brandedDrug, id) => {
  trackAgent('VIEW', 'DRUG', id);
  return sortDosages((brandedDrug?.drugs || []).find(drug => drug.id === id));
};

export const create = async (brandedDrugId, attributes) => {
  trackAgent('CREATE', 'DRUG', attributes.name);
  const permittedAttributes = getPermittedAttributes(attributes);
  return API.createDrug(brandedDrugId, permittedAttributes).then(
    ({ drug }) => drug
  );
};

export const update = async (brandedDrugId, { id, ...attributes }) => {
  trackAgent('UPDATE', 'DRUG', id);
  const permittedAttributes = getPermittedAttributes(attributes);
  return API.updateDrug(brandedDrugId, id, {
    id,
    ...permittedAttributes,
  }).then(({ drug }) => sortDosages(drug));
};

export const getPdfPreviewUrl = async (brandedDrugId, id) => {
  return API.getDrugPdfPreview(brandedDrugId, id).then(
    ({ preview: { pdfFileInBase64 } }) =>
      `data:application/pdf;base64,${pdfFileInBase64}`
  );
};
