<template>
  <div class="form-group mb-0">
    <label :for="name" v-if="!withoutLabel">
      {{ label }}
    </label>
    <select class="form-control" :id="name" :name="name" v-model="value">
      <option v-for="option in options" :key="option[0]" :value="option[0]">
        {{ option[1] }}
      </option>
    </select>
  </div>
</template>

<script>
import ModelInputMixin from '@/mixins/model-input';

export default {
  name: 'JobTitleInput',
  mixins: [ModelInputMixin],
  props: {
    sex: { type: String, default: 'female' },
  },
  computed: {
    options() {
      return Object.keys(this.$i18n.t('jobTitles')).map(titleKey => [
        titleKey,
        this.$i18n.t('jobTitles')[titleKey][this.sex],
      ]);
    },
  },
};
</script>
