<template>
  <sidebar-layout>
    <template v-slot:title>
      <font-awesome-icon :icon="['fad', 'dna']" class="mr-3" />
      <span>{{ $t('admin.molecules.list.title') }}</span>
    </template>

    <template v-slot:actions>
      <router-link
        :to="{ name: 'newMolecule' }"
        class="btn-sm btn-outline-primary"
        :title="$t('admin.molecules.list.newButton')"
        v-tooltip="$t('admin.molecules.list.newButton')"
      >
        <font-awesome-icon :icon="['fas', 'plus']" />
      </router-link>
    </template>

    <div class="p-2 space-y-2 border-b border-gray-100">
      <search-input @search="onSearch" />
    </div>

    <list
      :moleculeId="moleculeId"
      :class="{ 'list-group': true }"
      :list="filteredMolecules"
      v-if="hasMolecules"
    />
    <div v-else-if="query" class="px-4 py-5 font-semibold">
      {{ $t('admin.molecules.list.noMolecule') }}
    </div>
  </sidebar-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SidebarLayout from '@/components/admin/shared/sidebar-layout';
import List from './list';

export default {
  name: 'MoleculeSidebar',
  components: { SidebarLayout, List },
  props: ['moleculeId'],
  data() {
    return {
      query: null,
    };
  },
  computed: {
    ...mapState(['molecules']),
    filteredMolecules() {
      return this.services.molecule.search(this.molecules, this.query);
    },
    hasMolecules() {
      return !!this.molecules && this.filteredMolecules.length;
    },
  },
  methods: {
    ...mapActions(['listMolecules']),
    onSearch(query) {
      this.query = query;
    },
  },
  mounted() {
    this.listMolecules();
  },
};
</script>
