<template>
  <div>
    <div
      v-for="brandedDrug in list"
      :key="compositeKey(brandedDrug)"
      :class="{
        'hover:bg-gray-50': brandedDrug.id !== brandedDrugId,
        'border-l-2 border-blue-700 bg-indigo-50 hover:bg-indigo-100':
          brandedDrug.id === brandedDrugId,
      }"
    >
      <branded-drug
        class="px-4 border-b border-gray-100"
        :brandedDrug="brandedDrug"
        :drugId="drugId"
        :active="brandedDrug.id === brandedDrugId"
      />
    </div>
  </div>
</template>

<script>
import BrandedDrug from './branded-drug';

export default {
  name: 'BrandedDrugList',
  components: { BrandedDrug },
  props: {
    list: { type: Array, default: null },
    brandedDrugId: { type: String, default: null },
    drugId: { type: String, default: null },
  },
  methods: {
    compositeKey(brandedDrug) {
      return this.buildCompositeKey(
        brandedDrug.id,
        (brandedDrug.drugs || []).map(d => [d.id, d.name])
      );
    },
  },
};
</script>
