<template>
  <main-layout>
    <template v-slot:title>
      <font-awesome-icon :icon="topic.icon" class="mr-3" />
      <span>{{ title }}</span>
      <span class="mx-2">/</span>
      <span>{{ $t('admin.medicalReportLibrary.new.title') }}</span>
    </template>

    <library-item-form
      class="py-4 px-4"
      :topic="topic"
      :libraryItem="libraryItem"
      :persist="onPersist"
      @change="onChange"
      v-if="libraryItem"
    />
  </main-layout>
</template>

<script>
import MedicalReportLibraryMixin from '@/mixins/medical-report-library';
import MainLayout from '@/components/admin/shared/main-layout';
import LibraryItemForm from './form';

export default {
  name: 'NewMedicalReportHistoryItem',
  mixins: [MedicalReportLibraryMixin],
  components: { MainLayout, LibraryItemForm },
  data() {
    return { libraryItem: null };
  },
  computed: {
    title() {
      return this.topic.title[this.contentLocale];
    },
  },
  methods: {
    onChange(changes) {
      this.libraryItem = this.services.medicalReportLibrary.applyChanges(
        this.libraryItem,
        changes
      );
    },
    onPersist() {
      return this.services.medicalReportLibrary
        .create(this.topic.name, this.libraryItem)
        .then(libraryItem => {
          this.$router.push({
            name: 'editMedicalReportLibraryItem',
            params: {
              topicName: this.topic.name,
              libraryItemId: libraryItem.id,
            },
          });
        });
    },
  },
  watch: {
    services: {
      immediate: true,
      handler() {
        this.libraryItem = this.services.medicalReportLibrary.build();
      },
    },
  },
};
</script>
