<template>
  <main-layout>
    <template v-slot:title>
      {{ $t('admin.agentGroups.new.title') }}
    </template>

    <agent-group-form
      class="pb-4 px-4"
      :agentGroup="agentGroup"
      :persist="onPersist"
      @change="onChange"
    />
  </main-layout>
</template>

<script>
import merge from 'deepmerge';
import MainLayout from '@/components/admin/shared/main-layout';
import AgentGroupForm from './form';

export default {
  name: 'NewAgentGroup',
  components: { MainLayout, AgentGroupForm },
  data() {
    return {
      agentGroup: {
        name: '',
        agentIds: [],
        position: 99,
        system: false,
      },
    };
  },
  methods: {
    onChange(changes) {
      const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
      this.agentGroup = merge(this.agentGroup, changes, {
        arrayMerge: overwriteMerge,
      });
    },
    onPersist() {
      return this.services.agentGroup
        .create(this.agentGroup)
        .then(agentGroup => {
          this.$router.push({
            name: 'editAgentGroup',
            params: { agentGroupId: agentGroup.id },
          });
        });
    },
  },
};
</script>
