import Welcome from '@/components/welcome';

export default [
  {
    path: '/sign-in',
    name: 'signIn',
    component: () =>
      import(/* webpackChunkName: "bundled-sign-in" */ '@/views/sign-in.vue'),
  },
  {
    path: '/',
    component: () =>
      import(
        /* webpackChunkName: "bundled-workspace" */ '@/views/workspace.vue'
      ),
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        components: {
          default: Welcome,
        },
        props: {
          sidebar: true,
        },
        meta: {
          protected: true,
          transitionName: 'slide',
        },
      },
    ],
  },
  {
    path: '*',
    name: 'pageNotFound',
    component: () =>
      import(/* webpackChunkName: "bundled-404" */ '@/views/not-found.vue'),
  },
];
