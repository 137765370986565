<template>
  <div ref="scrollable">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'InfiniteScrolling',
  props: {
    onScroll: {
      type: Function,
      default: () => {},
    },
    isListening: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.$refs.scrollable.addEventListener('scroll', this.scroll, {
      passive: true,
    });
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scroll);
  },
  methods: {
    scroll(event) {
      var el = event.currentTarget;
      if (
        this.isListening &&
        el.scrollHeight - el.clientHeight - el.scrollTop < 50
      )
        this.onScroll();
    },
  },
};
</script>
