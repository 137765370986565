<template>
  <router-link
    :to="{ name: 'editMolecule', params: { moleculeId: molecule.id } }"
    tag="div"
    class="flex items-center py-5 text-gray-800"
    active-class="font-bold"
  >
    <h3>
      {{ molecule.name }}
    </h3>
  </router-link>
</template>

<script>
export default {
  name: 'MoleculeListItem',
  props: {
    molecule: { type: Object },
  },
};
</script>
