<template>
  <model-form
    class="py-6"
    :model="agentGroup"
    i18nScope="admin.agentGroups.form"
    :validate="isValid"
    @change="onChange"
    :on-submit="persist"
  >
    <text-input
      attributeName="name"
      :disabled="agentGroup.id && agentGroup.system"
    />

    <text-input attributeName="description" class="mt-6" />

    <div class="grid grid-cols-1 gap-4 mt-6">
      <checkbox-input attributeName="system" />
      <checkbox-input attributeName="addSystemAdministrators" />
      <checkbox-input attributeName="sortable" />
    </div>

    <agents-input attributeName="agentIds" class="mt-6" />
  </model-form>
</template>

<script>
import AgentsInput from './agents-input';

export default {
  name: 'AgentGroupForm',
  components: { AgentsInput },
  props: {
    agentGroup: { type: Object },
    persist: { type: Function, default: () => {} },
  },
  data() {
    return { agents: [] };
  },
  methods: {
    onChange(changes) {
      this.$emit('change', changes);
    },
    isValid() {
      return !this.isBlank(this.agentGroup.name);
    },
  },
};
</script>
