<template>
  <model-form
    :model="libraryItem"
    i18nScope="admin.medicalReportLibrary.form"
    :validate="services.medicalReportLibrary.isValid"
    :on-submit="persist"
    v-on="$listeners"
  >
    <tabs navClass="rounded-0 p-0">
      <tab
        :name="$t('admin.medicalReportLibrary.form.languages.fr')"
        :selected="true"
        v-on="$listeners"
      >
        <text-input attributeName="title" locale="fr" class="mt-5" />
      </tab>
      <tab
        :name="$t('admin.medicalReportLibrary.form.languages.en')"
        v-on="$listeners"
      >
        <text-input attributeName="title" locale="en" class="mt-5" />
      </tab>
    </tabs>

    <hr class="my-6" />

    <div class="grid grid-cols-2 gap-4">
      <checkbox-input attributeName="published" />
      <rating-input
        attributeName="severity"
        :customGetColor="getSeverityColor"
        v-if="!isCategory"
      />

      <select-input
        attributeName="parentId"
        class="col-span-2"
        v-if="allowParent"
      >
        <option value="">{{
          $t('admin.medicalReportLibrary.form.noParent')
        }}</option>
        <option
          v-for="parent in parentsTree"
          :key="parent.id"
          :value="parent.id"
        >
          {{ parentOptionName(parent) }}
        </option>
      </select-input>

      <div>
        <checkbox-input attributeName="canBeHidden" />
        <div
          class="bg-red-50 text-red-600 text-xs font-bold px-3 py-2"
          v-if="initialCanBeHidden && !libraryItem.canBeHidden"
        >
          {{ $t('admin.medicalReportLibrary.form.canBeHiddenWarning') }}
        </div>
      </div>

      <checkbox-input
        attributeName="triggerRDLetter"
        v-if="topic.triggerRDLetter"
      />
    </div>

    <hr class="my-6" />

    <agent-group-input attributeName="agentGroupIds" class="mb-6" />

    <nested-model-form
      v-model="libraryItem.filters"
      i18nScope="admin.medicalReportLibrary.form.filters"
      v-if="!isCategory"
    >
      <div class="grid grid-cols-12 gap-4 mt-3 mb-8">
        <gender-input
          attributeName="gender"
          :legacy="true"
          class="col-span-3"
        />
<!--        <age-range-input attributeName="age" class="col-span-9 h-100" />-->
      </div>
    </nested-model-form>
  </model-form>
</template>

<script>
export default {
  name: 'MedicalReportLibraryItemForm',
  props: {
    topic: { type: Object },
    libraryItem: { type: Object },
    persist: { type: Function, default: () => {} },
  },
  data() {
    return {
      parents: [],
      parentsTree: [],
      initialCanBeHidden: null,
    };
  },
  computed: {
    isNew() {
      return !this.libraryItem.id;
    },
    isCategory() {
      return this.topic.maxDepth > 1 && !this.libraryItem.parentId;
    },
    allowParent() {
      return this.topic.maxDepth > 1;
    },
  },
  methods: {
    getSeverityColor(index, value) {
      return this.services.medicalReportLibrary.getSeverityColor(index, value);
    },
    parentOptionName(parent) {
      var prefix = '';
      for (var i = 0; i < parent.depth; i++) {
        prefix = `--${prefix}`;
      }
      return `${prefix} ${parent.title[this.contentLocale]}`;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (!this.libraryItem.title.fr && this.$route.query.title)
          this.libraryItem.title.fr = this.$route.query.title;
      },
    },
    topic: {
      immediate: true,
      handler(newTopic) {
        this.$bind(
          'parents',
          this.services.medicalReportLibrary.findAllRoots(
            this.contentLocale,
            newTopic.name
          )
        );
      },
    },
    parents() {
      this.parentsTree = this.services.medicalReportLibrary.buildFlattenTree(
        this.parents,
        this.libraryItem.id
      );
    },
    libraryItem: {
      immediate: true,
      handler() {
        if (this.isNew || this.initialCanBeHidden !== null) return;
        if (!this.libraryItem.filters)
          this.libraryItem.filters = this.services.medicalReportLibrary.buildDefaultFilters();
        this.initialCanBeHidden = this.libraryItem.canBeHidden;
      },
    },
  },
};
</script>
