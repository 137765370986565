var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative inline-block",class:{
    [_vm.customClass]: true,
  }},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip),expression:"tooltip"}],staticClass:"focus:outline-none",class:{
      'dropdown-toggle': _vm.toggle,
      [_vm.buttonCustomClass]: !!_vm.buttonCustomClass,
      'flex items-center w-full': _vm.fullWidth,
    },attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false","disabled":_vm.disabled},on:{"click":function($event){$event.preventDefault();return _vm.openDropdown.apply(null, arguments)}}},[_vm._t("button",function(){return [_c('span',{staticClass:"d-flex align-items-center justify-content-center"},[(_vm.icon)?_c('font-awesome-icon',{attrs:{"icon":_vm.icon}}):_vm._e(),(_vm.label)?_c('span',{class:{ 'ml-3': _vm.icon }},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.label)?_c('font-awesome-icon',{class:{
            'ml-auto': _vm.fullWidth,
            'ml-3': !_vm.fullWidth,
          },attrs:{"icon":['fas', _vm.dropup ? 'caret-up' : 'caret-down']}}):_vm._e()],1)]})],2),_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeDropdown),expression:"closeDropdown"}],staticClass:"origin-top-right absolute mt-2 min-w-16 z-10 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none",class:{
      hidden: !_vm.open,
      'right-0': _vm.menuAlign === 'right',
      'left-0': _vm.menuAlign === 'left',
      'w-full': _vm.fullWidth,
    }},[_vm._t("default",null,{"closeDropdown":_vm.closeDropdown})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }