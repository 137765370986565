<template>
  <router-link
    :to="{
      name: 'editMedicalReportLibraryItem',
      params: { topicName: topic.name, libraryItemId: libraryItem.id },
    }"
    tag="div"
    class="flex items-center py-5"
    :class="{
      'text-gray-500': !libraryItem.published,
      'text-gray-900': libraryItem.published,
    }"
  >
    <h4
      :class="{
        italic: !libraryItem.published,
      }"
    >
      {{ title }}
    </h4>
  </router-link>
</template>

<script>
export default {
  name: 'LibraryTreeItem',
  props: {
    topic: { type: Object },
    libraryItem: { type: Object },
  },
  computed: {
    title() {
      return (
        this.libraryItem.title[this.contentLocale] ?? this.libraryItem.title
      );
    },
  },
};
</script>
