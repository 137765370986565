<template>
  <router-link
    :to="{ name: 'editAgent', params: { agentId: agent.id } }"
    tag="div"
    class="flex items-center py-5 text-gray-800"
    active-class="font-bold"
  >
    <avatar :user="agent" class="h-16 w-16 flex-shrink-0" />
    <div class="ml-4">
      <h3>{{ agent | agentFullName }}</h3>
      <p class="text-gray-400 text-sm">
        {{ agent | agentTitle }}
      </p>
      <p class="mt-2">
        <span
          class="flex-shrink-0 inline-block px-2 py-0.5 text-yellow-800 text-xs font-medium bg-yellow-100 rounded-full"
          v-if="agent.isDisabled"
        >
          {{ $t(`admin.agents.list.isDisabledTag.${agent.gender}`) }}
        </span>
      </p>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'AgentListItem',
  props: {
    agent: { type: Object },
  },
};
</script>
