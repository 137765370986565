<template>
  <main-layout>
    <template v-slot:title>
      {{ $t('admin.molecules.new.title') }}
    </template>

    <molecule-form
      class="py-4 px-4"
      :molecule="molecule"
      :persist="onPersist"
      @change="onChange"
      v-if="molecule"
    />
  </main-layout>
</template>

<script>
import { mapActions } from 'vuex';
import merge from 'deepmerge';
import MainLayout from '@/components/admin/shared/main-layout';
import MoleculeForm from './form';

export default {
  name: 'NewMolecule',
  components: { MainLayout, MoleculeForm },
  data() {
    return { molecule: null };
  },
  mounted() {
    this.molecule = this.services.molecule.build();
  },
  methods: {
    ...mapActions(['createMolecule']),
    onChange(changes) {
      this.molecule = merge(this.molecule, changes);
    },
    onPersist() {
      return this.createMolecule(this.molecule).then(molecule => {
        this.$router.push({
          name: 'editMolecule',
          params: { moleculeId: molecule.id },
        });
      });
    },
  },
};
</script>
