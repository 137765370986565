<template>
  <button
    :type="type"
    class="btn"
    :class="{ [buttonClass]: true, [miscButtonClass]: true }"
    :disabled="isInProgressState"
    @click="$emit('click')"
  >
    <span v-if="isDefaultState" data-button-label>{{ defaultLabel }}</span>

    <span v-if="isInProgressState" class="items-center justify-center">
      <font-awesome-icon :icon="['fal', 'circle-notch']" spin />
      <span class="ml-3" data-button-label>{{ inProgressLabel }}</span>
    </span>

    <span v-if="isSuccessState" class="items-center justify-center">
      <font-awesome-icon :icon="['fas', 'check']" />
      <span class="ml-3" data-button-label>{{ successLabel }}</span>
    </span>

    <span v-if="isFailState" class="items-center justify-center">
      <font-awesome-icon :icon="['fas', 'exclamation']" />
      <span class="ml-3" data-button-label>{{ failLabel }}</span>
    </span>
  </button>
</template>

<script>
import SubmitButtonMixin from '@/mixins/submit-button';

export default {
  name: 'SubmitButton',
  mixins: [SubmitButtonMixin],
};
</script>
