import * as AgentGroupService from './agent-group';
import * as AgentService from './agent';
import * as AgentTrackingService from './agent-tracking';
import * as AuthenticationService from './authentication';
import * as BrandedDrugService from './branded-drug';
import * as DrugService from './drug';
import * as MedicalReportLibraryService from './medical-report-library';
import * as MoleculeService from './molecule';
import * as SavedReplyService from './saved-reply';
import * as ConfigService from './config';

export default {
  agentGroup: AgentGroupService,
  agent: AgentService,
  agentTracking: AgentTrackingService,
  authentication: AuthenticationService,
  brandedDrug: BrandedDrugService,
  drug: DrugService,
  medicalReportLibrary: MedicalReportLibraryService,
  molecule: MoleculeService,
  savedReply: SavedReplyService,
  config: ConfigService,
};
