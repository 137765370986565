<template>
  <div class="flex items-center py-2 border-b border-gray-100">
    <font-awesome-icon
      :icon="['fas', 'grip-vertical']"
      class="handle cursor-move mr-6"
      v-if="sortable"
    />
    <avatar
      :user="agent"
      color="dark"
      :online="agent.online"
      class="mr-5 w-12 h-12"
    />
    <div>
      <h4 class="flex items-center">
        <span class="font-bold">{{ agent | agentFullName }}</span>
        <span
          class="bg-yellow-100 text-yellow-800 px-2 py-1 text-sm ml-2"
          v-if="agent.isDisabled"
        >
          {{ $t('admin.agentGroups.form.agentsInput.isDisabledTag') }}
        </span>
      </h4>
      <p class="text-gray-500 text-sm">{{ agent | agentTitle }}</p>
    </div>
    <destroy-button
      class="ml-auto"
      :text="$t('admin.agentGroups.form.agentsInput.destroy.text')"
      :buttonLabel="$t('admin.agentGroups.form.agentsInput.destroy.button')"
      @destroy="$emit('on-remove', agent.id)"
    />
  </div>
</template>

<script>
export default {
  name: 'AgentsInputListItem',
  props: {
    agent: { type: Object, required: true },
    sortable: { type: Boolean, default: false },
  },
};
</script>
