export default {
  show() {
    return this.isSystemAdmin() || this.isMedicalReportAdmin();
  },
  create() {
    return this.show();
  },
  destroy(libraryItem) {
    return (
      this.show() &&
      (libraryItem.leaf || !libraryItem.hasChildren) &&
      libraryItem.published
    );
  },
};
