<template>
  <main-layout>
    <template v-slot:title>
      <span v-if="brandedDrug">
        {{ $t('admin.drugs.new.title', { name: brandedDrug.name }) }}
      </span>
    </template>

    <drug-form
      class="py-4 px-4"
      :drug="drug"
      :persist="onPersist"
      @change="onChange"
      v-if="drug"
    />
  </main-layout>
</template>

<script>
import { mapActions } from 'vuex';
import merge from 'deepmerge';
import MainLayout from '@/components/admin/shared/main-layout';
import DrugForm from './form';

export default {
  name: 'NewDrug',
  components: { MainLayout, DrugForm },
  props: {
    brandedDrugId: { type: String, default: null },
  },
  data() {
    return { drug: null, brandedDrug: null };
  },
  async mounted() {
    this.drug = this.services.drug.build();
  },
  methods: {
    ...mapActions(['createDrug']),
    onChange(changes) {
      const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
      this.drug = merge(this.drug, changes, {
        arrayMerge: overwriteMerge,
      });
    },
    onPersist() {
      return this.createDrug({
        brandedDrugId: this.brandedDrugId,
        drug: this.drug,
      }).then(drug => {
        this.$router.push({
          name: 'editDrug',
          params: { brandedDrugId: this.brandedDrug.id, drugId: drug.id },
        });
      });
    },
  },
  watch: {
    brandedDrugId: {
      immediate: true,
      async handler() {
        this.brandedDrug = await this.services.brandedDrug.find(
          this.brandedDrugId
        );
      },
    },
  },
};
</script>
