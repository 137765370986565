<template>
  <sidebar-layout>
    <template v-slot:title>
      <font-awesome-icon :icon="['fad', 'user-md']" class="mr-3" />
      <span>{{ $t('admin.agents.list.title') }}</span>
    </template>

    <template v-slot:actions>
      <router-link
        :to="{ name: 'newAgent' }"
        class="btn-sm btn-outline-primary"
      >
        <font-awesome-icon :icon="['fas', 'plus']" />
      </router-link>
    </template>

    <div class="p-2 space-y-2 border-b border-gray-100">
      <search-input @search="onSearch" />
    </div>

    <category
      title="doctors"
      :agentId="agentId"
      :list="splittedAgents.doctors"
    />

    <category title="nurses" :agentId="agentId" :list="splittedAgents.nurses" />

    <category title="others" :agentId="agentId" :list="splittedAgents.others" />

    <category
      title="disabled"
      :agentId="agentId"
      :list="splittedAgents.disabled"
    />

    <div v-if="!hasAgents" class="px-4 py-5 font-semibold">
      {{ $t('admin.agents.list.noAgent') }}
    </div>
  </sidebar-layout>
</template>

<script>
import SidebarLayout from '@/components/admin/shared/sidebar-layout';
import Category from './list/category.vue';

export default {
  name: 'AgentSidebar',
  components: { SidebarLayout, Category },
  props: ['agentId'],
  data() {
    return {
      allAgents: null,
      query: null,
    };
  },
  mounted() {
    this.$bind('allAgents', this.services.agent.findAll(true));
  },
  computed: {
    splittedAgents() {
      let agents = this.services.agent.filter(this.allAgents, {});
      agents = this.services.agent.search(agents, this.query);
      return this.services.agent.splitByCategories(agents);
    },
    hasAgents() {
      return !!this.allAgents && this.splittedAgents.agents.length;
    },
  },
  methods: {
    onSearch(query) {
      this.query = query;
    },
  },
};
</script>
