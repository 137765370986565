<template>
  <li class="mr-2">
    <tag
      :deletable="editable"
      :danger="isHighestSeverity"
      @delete="$emit('delete')"
    >
      <font-awesome-icon
        :icon="['fad', 'eye-slash']"
        class="mr-3"
        v-if="libraryItem.canBeHidden && libraryItem.hidden"
      />
      <font-awesome-icon
        :icon="['fad', 'eye']"
        class="mr-3"
        v-if="libraryItem.canBeHidden && !libraryItem.hidden"
      />
      <span class="font-bold">{{ title }}</span>
      <severity-badge
        class="ml-2"
        :severity="libraryItem.severity"
        v-if="!isLowestSeverity && !isHighestSeverity"
      />
    </tag>
  </li>
</template>

<script>
export default {
  name: 'MedicalReportItem',
  props: {
    libraryItem: { type: Object, required: true },
    editable: { type: Boolean, default: false },
  },
  computed: {
    title() {
      return this.libraryItem.title[this.contentLocale];
    },
    isHighestSeverity() {
      return this.libraryItem.severity === 4;
    },
    isLowestSeverity() {
      return this.libraryItem.severity === 0;
    },
  },
};
</script>
