<template>
  <dropdown :label="label" :toggle="true" menuAlign="right" ref="dropdown">
    <button
      class="dropdown-item"
      :class="{ 'text-dark': value === locale }"
      v-for="locale in allLocales"
      :key="locale"
      @click="select(locale)"
    >
      <font-awesome-icon icon="check" class="mr-2" v-if="value === locale" />
      {{ $t(`locales.${locale}`) }}
    </button>
  </dropdown>
</template>

<script>
export default {
  name: 'LocalePicker',
  props: {
    value: { type: String, default: 'en' },
  },
  computed: {
    label() {
      return this.$i18n.t(`locales.${this.value}`);
    },
  },
  methods: {
    select(locale) {
      this.$emit('input', locale);
      this.$refs.dropdown.closeDropdown();
    },
  },
};
</script>
