import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUserCog,
  faUser,
  faUserTag,
  faCircle,
  faExclamation,
  faAddressBook,
  faUserMd,
  faInbox,
  faStarOfLife,
  faSearch,
  faPlus as fasPlus,
  faDirections,
  faCaretDown,
  faCaretUp,
  faTools,
  faImage,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCommentAlt as farCommentAlt,
  faEllipsisV,
  faTimesCircle,
  faTrashAlt,
  faHeartRate,
  faComments,
  faCommentLines,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faListUl,
  faClock as farClock,
  faImagePolaroid as farImagePolaroid,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faHandPointRight,
  faHandPaper,
  faPaperclip,
  faSignOut,
  faCommentAlt,
  faPaperPlane,
  faCopy,
  faCamera,
  faCheckSquare,
  faTimesSquare,
  faSmile,
  faCircleNotch,
  faLongArrowLeft,
  faLongArrowRight,
  faInfoCircle,
  faRocket,
  faPlus,
  faTimes,
  faSearch as falSearch,
  faCapsules,
  faBolt,
  faAllergies,
  faUserMdChat,
  faNotesMedical,
  faChevronUp,
  faChevronDown,
  faClipboardPrescription,
  faClone,
  faFileAlt,
  faComputerClassic,
} from '@fortawesome/pro-light-svg-icons';
import {
  faCheck,
  faChevronRight,
  faClipboardUser,
  faInboxOut,
  faUserCowboy,
  faGripVertical,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faUserCowboy as fadUserCowboy,
  faUserCog as fadUserCog,
  faUserClock as fadUserClock,
  faUserTag as fadUserTag,
  faAddressBook as fadAddressBook,
  faUserMd as fadUserMd,
  faTools as fadTools,
  faUserFriends as fadUserFriends,
  faBookMedical as fadBookMedical,
  faHeartbeat as fadHeartbeat,
  faChild as fadChild,
  faBaby as fadBaby,
  faDna as fadDna,
  faPills as fadPills,
  faCheckCircle as fadCheckCircle,
  faClipboardPrescription as fadClipboardPrescription,
  faEyeSlash as fadEyeSlash,
  faEye as fadEye,
  faSnooze as fadSnooze,
  faSlidersVSquare as fadSlidersVSquare,
} from '@fortawesome/pro-duotone-svg-icons';

import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome';

library.add(faUserCog);
library.add(fadUserCog);
library.add(faUser);
library.add(faUserTag);
library.add(fadUserTag);
library.add(faUserCowboy);
library.add(fadUserCowboy);
library.add(faUserMd);
library.add(fadUserMd);
library.add(faInbox);
library.add(faInboxOut);
library.add(faCheck);
library.add(faHandPointRight);
library.add(faChevronRight);
library.add(faHandPaper);
library.add(faPaperclip);
library.add(faSignOut);
library.add(faCommentAlt);
library.add(faPaperPlane);
library.add(faAddressBook);
library.add(fadAddressBook);
library.add(faClipboardUser);
library.add(farCommentAlt);
library.add(faEllipsisV);
library.add(faCopy);
library.add(faCamera);
library.add(faCircle);
library.add(faCheckSquare);
library.add(faTimesSquare);
library.add(faTimesCircle);
library.add(faSmile);
library.add(faCircleNotch);
library.add(faExclamation);
library.add(faTrashAlt);
library.add(faLongArrowLeft);
library.add(faLongArrowRight);
library.add(faInfoCircle);
library.add(faRocket);
library.add(faStarOfLife);
library.add(faPlus);
library.add(fasPlus);
library.add(faSearch);
library.add(faDirections);
library.add(faHeartRate);
library.add(faCaretDown);
library.add(faCaretUp);
library.add(faRocket);
library.add(faTimes);
library.add(faCommentLines);
library.add(faComments);
library.add(falSearch);
library.add(faCapsules);
library.add(faAngleDoubleRight);
library.add(faAngleDoubleLeft);
library.add(faTools);
library.add(fadTools);
library.add(fadUserFriends);
library.add(faListUl);
library.add(faBolt);
library.add(faGripVertical);
library.add(fadBookMedical);
library.add(fadHeartbeat);
library.add(faAllergies);
library.add(fadBaby);
library.add(fadChild);
library.add(faUserMdChat);
library.add(faImage);
library.add(faNotesMedical);
library.add(fadDna);
library.add(fadPills);
library.add(faChevronUp);
library.add(faChevronDown);
library.add(faClipboardPrescription);
library.add(fadClipboardPrescription);
library.add(faClone);
library.add(fadCheckCircle);
library.add(faFileAlt);
library.add(fadEyeSlash);
library.add(fadEye);
library.add(fadUserClock);
library.add(farClock);
library.add(fadSnooze);
library.add(faComputerClassic);
library.add(farImagePolaroid);
library.add(fadSlidersVSquare);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
