<template>
  <main-layout>
    <template v-slot:title>
      {{ $t('admin.molecules.edit.title') }}
    </template>

    <molecule-form
      class="py-4 px-4"
      :key="molecule.id"
      :molecule="molecule"
      :persist="onPersist"
      @change="onChange"
      v-if="molecule"
    />
  </main-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import merge from 'deepmerge';
import MainLayout from '@/components/admin/shared/main-layout';
import MoleculeForm from './form';

export default {
  name: 'EditMolecule',
  components: { MainLayout, MoleculeForm },
  props: ['moleculeId'],
  data() {
    return {
      molecule: null,
    };
  },
  created() {
    this.$watch(
      vm => (vm.molecules, vm.moleculeId, Date.now()),
      () => this.fetchMolecule(),
      { immediate: true }
    );
  },
  computed: {
    ...mapState(['molecules']),
  },
  methods: {
    ...mapActions(['updateMolecule']),
    onChange(changes) {
      this.molecule = merge.all([
        this.molecule,
        changes,
        { id: this.moleculeId },
      ]);
    },
    onPersist() {
      return this.updateMolecule(this.molecule);
    },
    fetchMolecule() {
      this.molecule = this.services.molecule.find(
        this.molecules,
        this.moleculeId
      );
    },
  },
};
</script>
