<template>
  <div>
    <router-link
      v-for="drug in brandedDrug.drugs"
      :key="drug.id"
      :to="{
        name: 'editDrug',
        params: { brandedDrugId: brandedDrug.id, drugId: drug.id },
      }"
      tag="div"
      class="mb-4 cursor-pointer text-gray-500"
      activeClass="font-bold text-black"
      title="drug-name"
    >
      {{ drug.shortLabel }}
    </router-link>

    <router-link
      :to="{ name: 'newDrug', params: { brandedDrugId: brandedDrug.id } }"
      class="mt-6 btn btn-outline-primary btn-sm block text-center"
    >
      {{ $t('admin.drugs.newButton') }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'DrugList',
  props: {
    brandedDrug: { type: Object },
    drugId: { type: String },
  },
};
</script>
