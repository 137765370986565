<template>
  <model-form
    :model="molecule"
    i18nScope="admin.molecules.form"
    :validate="services.molecule.isValid"
    :on-submit="persist"
    v-on="$listeners"
  >
    <text-input attributeName="name" />

    <div class="grid grid-cols-2 gap-4 mt-6">
      <text-input attributeName="shortLabel" />
      <text-input attributeName="mobileLabel" />
    </div>

    <textarea-input class="mt-6" attributeName="details" />
  </model-form>
</template>

<script>
export default {
  name: 'MoleculeForm',
  props: {
    molecule: { type: Object },
    persist: { type: Function, default: () => {} },
  },
};
</script>
