import Welcome from '@/components/welcome';
import SavedRepliesSidebar from '@/components/admin/saved-replies/sidebar';
import NewSavedReply from '@/components/admin/saved-replies/new';
import EditSavedReply from '@/components/admin/saved-replies/edit';

export default [
  {
    path: 'saved-replies',
    name: 'savedReplies',
    components: {
      default: Welcome,
      sidebar: SavedRepliesSidebar,
    },
    props: {
      sidebar: true,
    },
    meta: {
      protected: true,
      sidebar: true,
      policy: { scope: 'savedReply', action: 'show' },
    },
  },
  {
    path: 'saved-replies/new',
    name: 'newSavedReply',
    components: {
      default: NewSavedReply,
      sidebar: SavedRepliesSidebar,
    },
    props: {
      sidebar: true,
      default: true,
    },
    meta: {
      protected: true,
      sidebar: true,
      policy: { scope: 'savedReply', action: 'show' },
    },
  },
  {
    path: 'saved-replies/:savedReplyId/edit',
    name: 'editSavedReply',
    components: {
      default: EditSavedReply,
      sidebar: SavedRepliesSidebar,
    },
    props: {
      sidebar: true,
      default: true,
    },
    meta: {
      protected: true,
      sidebar: true,
      policy: { scope: 'savedReply', action: 'show' },
    },
  },
];
