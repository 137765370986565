<template>
  <model-form
    :model="drug"
    i18nScope="admin.drugs.form"
    :validate="services.drug.isValid"
    :on-submit="persist"
    v-on="$listeners"
  >
    <text-input attributeName="name" :max-characters="100" />

    <div class="grid grid-cols-2 gap-4 mt-6">
      <text-input attributeName="shortLabel" />
      <text-input attributeName="mobileLabel" />
    </div>

    <hr class="my-6" />

    <dosages-input attributeName="dosages" />
  </model-form>
</template>

<script>
import DosagesInput from './dosages-input';

export default {
  name: 'DrugForm',
  components: { DosagesInput },
  props: {
    drug: { type: Object },
    persist: { type: Function, default: () => {} },
  },
};
</script>
