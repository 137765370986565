<template>
  <div>
    <div v-for="agentGroup in list" :key="agentGroup.id">
      <div
        :class="{
          'hover:bg-gray-50': agentGroup.id !== agentGroupId,
          'border-l-2 border-blue-700 bg-indigo-50 hover:bg-indigo-100':
            agentGroup.id === agentGroupId,
        }"
      >
        <div class="cursor-pointer border-b border-gray-100 px-4">
          <group :agentGroup="agentGroup" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Group from './group';

export default {
  name: 'AgentGroupList',
  components: { Group },
  props: {
    list: { type: Array, default: null },
    agentGroupId: { type: String, default: null },
  },
};
</script>
