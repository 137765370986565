<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'NestedModelForm',
  props: {
    value: { type: Object, default: () => {} },
    modelName: { type: String, default: 'nested-model' },
    i18nScope: { type: String },
  },
  provide() {
    const self = this;
    return {
      parentModel() {
        return self.value;
      },
      parentI18nScope: this.i18nScope,
      modelName: `${this.modelName}`,
    };
  },
  created() {
    this.$on('change', changes => this.onChange(changes));
  },
  methods: {
    onChange(changes) {
      const newValue = { ...this.value, ...changes };
      this.$emit('input', newValue);
    },
  },
};
</script>
