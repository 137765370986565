<template>
  <main-layout>
    <template v-slot:title>
      {{ $t('admin.agentGroups.edit.title') }}
    </template>

    <template v-slot:actions>
      <destroy-button
        :text="$t('admin.agentGroups.edit.actions.destroy.text')"
        :buttonLabel="$t('admin.agentGroups.edit.actions.destroy.button')"
        @destroy="onDestroy"
        v-if="$p('agentGroup', agentGroup, 'destroy')"
      />
    </template>

    <agent-group-form
      class="pb-4 px-4"
      :agentGroup="agentGroup"
      :persist="onPersist"
      @change="onChange"
      v-if="agentGroup"
    />
  </main-layout>
</template>

<script>
import merge from 'deepmerge';
import MainLayout from '@/components/admin/shared/main-layout';
import AgentGroupForm from './form';

export default {
  name: 'EditAgentGroup',
  components: { MainLayout, AgentGroupForm },
  props: ['agentGroupId'],
  data() {
    return { agentGroup: null };
  },
  methods: {
    onChange(changes) {
      const overwriteMerge = (destinationArray, sourceArray) => sourceArray;

      this.agentGroup = merge.all(
        [this.agentGroup, changes, { id: this.agentGroupId }],
        { arrayMerge: overwriteMerge }
      );
    },
    onPersist() {
      return this.services.agentGroup.update(this.agentGroup);
    },
    onDestroy() {
      this.services.agentGroup
        .destroy(this.agentGroup)
        .then(() => this.$router.push({ name: 'agentGroups' }));
    },
  },
  watch: {
    agentGroupId: {
      immediate: true,
      handler(agentGroupId) {
        this.$bind('agentGroup', this.services.agentGroup.find(agentGroupId));
      },
    },
  },
};
</script>
