<template>
  <div class="dot-loader" :style="{ width, height }">
    <Lottie
      :options="animationOptions"
      :width="94"
      :height="72"
      class="dot-loader-lottie"
    />
  </div>
</template>

<script>
import Lottie from 'vue-lottie';
import animationData from '@/assets/animations/dot-loader.json';

const DEFAULT_SIZE = 20;

export default {
  name: 'DotLoader',
  components: { Lottie },
  computed: {
    width() {
      return `${DEFAULT_SIZE * 1.3}px`;
    },
    height() {
      return `${DEFAULT_SIZE}px`;
    },
    animationData() {
      return animationData;
    },
    animationOptions() {
      return {
        animationData: this.animationData,
        autoplay: true,
        loop: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.dot-loader {
  position: relative;
  overflow: hidden;

  &-lottie {
    position: absolute;
    left: calc((94px / 2 * -1) + 13px);
    top: calc((72px / 2 * -1) + 10px);
    margin: 0;
  }
}
</style>
