<template>
  <div class="form-group mb-0">
    <label :for="name" v-if="!withoutLabel">
      {{ label }}
    </label>
    <select class="form-control" :id="name" :name="name" v-model="customValue">
      <option v-for="option in options" :key="option[0]" :value="option[0]">
        {{ option[1] }}
      </option>
    </select>
  </div>
</template>

<script>
import ModelInputMixin from '@/mixins/model-input';

export default {
  name: 'GenderInput',
  mixins: [ModelInputMixin],
  props: {
    legacy: { type: Boolean, default: false },
  },
  computed: {
    options() {
      return ['all', 'girl', 'boy'].map(value => [
        this.legacy ? value : value.toUpperCase(),
        this.$t(`form.genderInput.options.${value}`),
      ]);
    },
    customValue: {
      get() {
        const allValue = this.legacy ? 'all' : 'ALL';
        return this.isBlank(this.value) ? allValue : this.value;
      },
      set(value) {
        this.$parent.$emit('change', {
          ...this.safeModel,
          [this.attributeName]: value,
        });
      },
    },
  },
};
</script>
