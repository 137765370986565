import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/locales';
import moment from 'moment';
import 'moment/locale/fr';

Vue.use(VueI18n);

const AVAILABLE_LOCALES = ['en', 'fr'];

// at first, try to fetch the browser locale
var locale = 'en';
const language = navigator.languages[0];
if (language) {
  locale = language.split('-')[0];
  if (AVAILABLE_LOCALES.indexOf(locale) === -1) locale = null;
}

// console.log('browser locale', locale);

const i18n = new VueI18n({
  locale,
  fallbackLocale: AVAILABLE_LOCALES[0],
  messages,
});

export { AVAILABLE_LOCALES };

export const setUiLocale = newLocale => {
  i18n.locale = newLocale;
  moment.locale(newLocale);
};

export const getLastKnownUiLocale = () => {
  return localStorage.getItem('locale');
};

export default i18n;
