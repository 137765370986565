<template>
  <div class="rich-text-input">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="flex items-center">
        <dropdown
          :toggle="false"
          :dropup="true"
          buttonCustomClass="text-blue-600 w-8 h-8 border-blue-600 border rounded-md transition-colors hover:bg-blue-600 hover:text-white"
          customClass="dropdown--emoji"
          ref="emojiDropdown"
        >
          <template v-slot:button>
            <font-awesome-icon :icon="['fal', 'smile']" />
          </template>

          <VEmojiPicker
            v-show="true"
            :labelSearch="$i18n.t(`components.richTextInput.emoji.search`)"
            @select="placeholders.emoji"
          />
        </dropdown>

        <button
          type="button"
          class="text-blue-600 w-8 h-8 border-blue-600 border rounded-md transition-colors hover:bg-blue-600 hover:text-white ml-3"
          :class="{ active: isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <font-awesome-icon :icon="['far', 'list-ul']" />
        </button>

        <dropdown
          :toggle="true"
          menuAlign="left"
          buttonCustomClass="text-blue-600 w-8 h-8 border-blue-600 border rounded-md transition-colors hover:bg-blue-600 hover:text-white ml-3"
          ref="placeholdersDropdown"
        >
          <template v-slot:button>
            <font-awesome-icon :icon="['fal', 'bolt']" />
          </template>

          <div class="px-4 py-4 flex flex-col items-start">
            <button
              type="button"
              class="py-2 hover:bg-gray-100 w-full"
              @click="placeholders.userFirstName"
            >
              {{ $t('admin.savedReplies.form.placeholders.userFirstName') }}
            </button>
            <button
              type="button"
              class="py-2 hover:bg-gray-100 w-full"
              @click="placeholders.kidFirstName"
            >
              {{ $t('admin.savedReplies.form.placeholders.kidFirstName') }}
            </button>
            <button
              type="button"
              class="py-2 hover:bg-gray-100 w-full"
              @click="placeholders.kidGenderPronoun"
            >
              {{ $t('admin.savedReplies.form.placeholders.kidGenderPronoun') }}
            </button>
            <button
              type="button"
              class="py-2 hover:bg-gray-100 w-full"
              @click="placeholders.kidGenderPronounObject"
              v-if="language === 'en'"
            >
              {{
                $t(
                  'admin.savedReplies.form.placeholders.kidGenderPronounObject'
                )
              }}
            </button>
            <button
              type="button"
              class="py-2 hover:bg-gray-100 w-full"
              @click="placeholders.kidGenderAgreement"
              v-if="language === 'fr'"
            >
              {{
                $t('admin.savedReplies.form.placeholders.kidGenderAgreement')
              }}
            </button>
            <button
              type="button"
              class="py-2 hover:bg-gray-100 w-full"
              @click="placeholders.kidGenderArticle"
              v-if="language === 'fr'"
            >
              {{ $t('admin.savedReplies.form.placeholders.kidGenderArticle') }}
            </button>
          </div>
        </dropdown>
      </div>
    </editor-menu-bar>

    <div class="form-control mt-4">
      <editor-content :editor="editor" />
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import { HardBreak, ListItem, BulletList } from 'tiptap-extensions';
import VEmojiPicker from 'v-emoji-picker';
import { insertHTML } from '@/shared/tiptap';

export default {
  name: 'RichTextInput',
  components: { EditorContent, EditorMenuBar, VEmojiPicker },
  props: ['value', 'language'],
  data() {
    return {
      editor: null,
    };
  },
  mounted() {
    this.editor = new Editor({
      extensions: [new HardBreak(), new ListItem(), new BulletList()],
      content: this.value,
      onUpdate: ({ getHTML }) => {
        var content = getHTML();
        this.$emit('input', content);
      },
    });
  },
  computed: {
    placeholders() {
      return {
        userFirstName: () => {
          insertHTML(this.editor, '{userFirstName}');
          this.$refs.placeholdersDropdown.closeDropdown();
        },
        kidFirstName: () => {
          insertHTML(this.editor, '{kidFirstName}');
          this.$refs.placeholdersDropdown.closeDropdown();
        },
        kidGenderPronoun: () => {
          insertHTML(this.editor, '{kidGenderPronoun}');
          this.$refs.placeholdersDropdown.closeDropdown();
        },
        kidGenderPronounObject: () => {
          insertHTML(this.editor, '{kidGenderPronounObject}');
          this.$refs.placeholdersDropdown.closeDropdown();
        },
        kidGenderAgreement: () => {
          insertHTML(this.editor, '{kidGenderAgreement}');
          this.$refs.placeholdersDropdown.closeDropdown();
        },
        kidGenderArticle: () => {
          insertHTML(this.editor, '{kidGenderArticle}');
          this.$refs.placeholdersDropdown.closeDropdown();
        },
        emoji: emoji => {
          insertHTML(this.editor, emoji.data);
          this.$refs.emojiDropdown.closeDropdown();
        },
      };
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>
