<template>
  <router-link
    :to="{ name: 'editSavedReply', params: { savedReplyId: savedReply.id } }"
    tag="div"
    class="flex items-center py-5"
    :class="{
      'text-gray-500': !savedReply.published,
      'text-gray-900': savedReply.published,
    }"
  >
    <h3
      class="mb-0"
      :class="{
        italic: !savedReply.published,
      }"
    >
      {{ title }}
    </h3>
    <font-awesome-icon
      :icon="['far', 'image-polaroid']"
      class="mx-3"
      v-if="savedReply.isImage"
      fixed-width
    />
  </router-link>
</template>

<script>
export default {
  name: 'SavedReplyListItem',
  props: {
    savedReply: { type: Object },
  },
  computed: {
    title() {
      return this.savedReply.title[this.contentLocale] ?? this.savedReply.title;
    },
  },
};
</script>
