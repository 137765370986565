import Vue from 'vue';
import Pundit from './internal/pundit';
import policies from '@/policies';
import helpers from '@/policies/helpers';
import store from '@/store';

Vue.use(Pundit, {
  getCurrentUser: function() {
    // the Vue.policy helper might be called outside the context of components,
    // within services for instance (not ideal for testing), that's why we need
    // a fallback store.
    let safeStore = this.$store || store;
    return safeStore.getters.getAgentWithGroups;
  },
  getGroups: function() {
    let safeStore = this.$store || store;
    return safeStore.state.groups;
  },
  policies,
  helpers,
});
