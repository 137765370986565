<template>
  <div class="form-group">
    <label :for="name">{{ label }}</label>

    <div class="mt-2">
      <draggable
        tag="ul"
        :list="selectedAgents"
        :disabled="!sortable"
        handle=".handle"
        @sort="onSort"
      >
        <list-item
          v-for="agent in selectedAgents"
          :key="agent.id"
          :agent="agent"
          :sortable="sortable"
          @on-remove="onRemove"
        />
      </draggable>

      <add-item class="mt-6" :selectedAgentIds="agentIds" @on-add="onAdd" />
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import ModelInputMixin from '@/mixins/model-input';
import ListItem from './list-item';
import AddItem from './add-item';

export default {
  name: 'AgentsInput',
  mixins: [ModelInputMixin],
  components: { Draggable, ListItem, AddItem },
  data() {
    return { agents: [], selectedAgents: [] };
  },
  computed: {
    agentIds() {
      return this.safeModel.agentIds;
    },
    sortable() {
      return this.safeModel.sortable;
    },
  },
  methods: {
    onAdd(agentId) {
      if (this.isBlank(agentId)) return;
      this.update([...this.safeModel.agentIds, agentId]);
    },
    onSort() {
      this.update(this.selectedAgents.map(agent => agent.id));
    },
    onRemove(agentId) {
      this.update(this.safeModel.agentIds.filter(id => id !== agentId));
    },
    update(agentIds) {
      this.$parent.$emit('change', { ...this.safeModel, agentIds });
    },
  },
  watch: {
    agentIds: {
      immediate: true,
      handler(agentIds) {
        this.services.agent
          .findAllByIds(agentIds)
          .then(
            agents =>
              (this.selectedAgents = this.sortable
                ? agents
                : this.services.agent.sortByDisabledLast(agents))
          );
      },
    },
  },
};
</script>
