<template>
  <dropdown
    buttonCustomClass="text-red-600 w-8 h-8 border-red-600 border rounded-md transition-colors hover:bg-red-600 hover:text-white"
    menuAlign="right"
    :dropup="dropup"
    ref="dropdown"
  >
    <template v-slot:button>
      <span>
        <font-awesome-icon :icon="['far', 'trash-alt']" />
      </span>
    </template>

    <div class="mx-5 my-3 text-sm">
      <p class="text-sm">
        {{ text }}
      </p>
      <button
        v-on:click.stop.prevent="destroy"
        class="px-2 py-1 rounded-md border border-gray-200 hover:bg-gray-200 text-gray-900 mt-3 w-full"
        type="button"
      >
        <span class="d-flex align-items-center">
          <font-awesome-icon :icon="['fal', 'check-square']" />
          <span class="ml-3">{{ buttonLabel }}</span>
        </span>
      </button>
    </div>
  </dropdown>
</template>

<script>
export default {
  name: 'DestroyButton',
  props: {
    text: { type: String, default: undefined },
    buttonLabel: { type: String, default: undefined },
    buttonSize: { type: String, required: false, default: 'md' },
    dropup: { type: Boolean, required: false, default: false },
  },
  methods: {
    destroy() {
      this.$refs.dropdown.closeDropdown();
      this.$emit('destroy');
    },
  },
};
</script>
