const checkPolicy = function(type, instance, action, options) {
  const { getCurrentUser, getGroups, helpers, policies } = options;

  if (type === null || type === undefined) throw '[Pundit] a type is required';

  const policy = policies[`${type}Policy`];

  if (policy === null || policy === undefined)
    throw `[Pundit] Unknown *${type}* policy`;

  const actionFn = policy[action];

  if (actionFn === undefined)
    throw `[Pundit] Unknown *${action}* rule for the *${type}* policy`;

  const boundedActionFn = actionFn.bind({
    user: getCurrentUser.bind(this)(),
    groups: getGroups.bind(this)(),
    ...policy,
    ...helpers,
  });

  return boundedActionFn(instance);
};

// Just because the Ruby on Rails Pundit gem is awesome
const Pundit = {
  install(Vue, options) {
    Vue.prototype.$policy = function(type, instance, action) {
      let self = this;
      if (action === undefined) [instance, action] = [null, instance];
      return checkPolicy.bind(self)(type, instance, action, options);
    };

    // Alias
    Vue.prototype.$p = Vue.prototype.$policy;

    // Global method
    Vue.policy = Vue.prototype.$policy;
  },
};

export default Pundit;
