<template>
  <tabs navClass="rounded-0">
    <tab
      v-for="(locale, index) in locales"
      :key="locale"
      :name="$t(`admin.savedReplies.form.languages.${locale}`)"
      :selected="index === 0"
    >
      <div class="form-group mt-5">
        <label :for="`saved-reply-${locale}-title`">{{
          $t('admin.savedReplies.form.title')
        }}</label>
        <input
          type="text"
          class="form-control"
          :id="`saved-reply-${locale}-title`"
          :value="savedReply.title[locale]"
          @input="onChange('title', locale, $event.target.value)"
        />
      </div>

      <div v-if="savedReply.leaf">
        <div v-if="savedReply.isImage">
          <div class="form-group">
            <label :for="`saved-reply-image-${locale}`">{{
              $t('admin.savedReplies.form.image')
            }}</label>
            <input
              type="text"
              class="form-control"
              :id="`saved-reply-image-${locale}`"
              :value="savedReply.image[locale]"
              @input="onChange('image', locale, $event.target.value)"
            />
          </div>
        </div>
        <div v-else>
          <div class="form-group mt-4">
            <label :for="`saved-reply-content-${locale}`">{{
              $t('admin.savedReplies.form.content')
            }}</label>
            <div class="flex mt-2">
              <rich-text-input
                :value="savedReply.content[locale]"
                @input="content => onChangeContent(locale, content)"
                :language="locale"
                class="w-1/2 mt-0"
              />
              <div class="w-1/2 ml-4 flex flex-col">
                <h3 class="text-gray-400 font-semibold">
                  {{ $t('admin.savedReplies.form.previewContent') }}
                </h3>
                <div
                  class="mt-6 whitespace-pre-wrap py-4 px-4 bg-gray-50 rounded-md h-full"
                  v-html="contentPreview[locale]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </tab>
  </tabs>
</template>

<script>
import { previewMobileContentSample } from '@/services/saved-reply';

export default {
  name: 'SavedReplyContentForm',
  props: {
    savedReply: { type: Object },
  },
  computed: {
    locales() {
      return ['fr', 'en'];
    },
    contentPreview() {
      return Object.fromEntries(
        this.locales.map(locale => [
          locale,
          previewMobileContentSample(this.savedReply, locale),
        ])
      );
    },
  },
  methods: {
    onChangeContent(locale, value) {
      this.onChange('content', locale, this.$sanitize(value));
    },
    onChange(name, locale, value) {
      this.$emit('change', { [name]: { [locale]: value } });
    },
  },
};
</script>
