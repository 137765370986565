<template>
  <div class="flex flex-col h-full relative z-0">
    <div class="h-20 px-4 border-b border-gray-100 flex items-center">
      <h2 class="font-bold text-lg">
        <slot name="title">Title goes here</slot>
      </h2>

      <div class="ml-auto flex h-full items-center">
        <slot name="actions"></slot>
      </div>
    </div>

    <div class="flex-1 flex overflow-hidden">
      <div class="flex-1 overflow-y-scroll">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminMainLayout',
};
</script>
