<template>
  <form @submit.stop.prevent="search">
    <div class="border border-gray-200 flex items-center">
      <div class="input-group-prepend">
        <button class="btn btn-ico btn-minimal" type="submit">
          <font-awesome-icon :icon="['fal', 'search']" />
        </button>
      </div>

      <input
        type="text"
        v-model="query"
        class="border-0 py-2 rounded-none focus:outline-none flex-1"
        :placeholder="$t('components.searchInput.placeholder')"
        :aria-label="$t('components.searchInput.placeholder')"
      />
      <span class="form-control-clear" v-if="query">
        <button
          class="btn btn-link btn-ico"
          @click.stop.prevent="reset"
          type="button"
        >
          <font-awesome-icon :icon="['fal', 'times']" />
        </button>
      </span>
    </div>
  </form>
</template>

<script>
export default {
  name: 'SearchInput',
  props: {
    placeholder: { type: String },
  },
  data() {
    return {
      query: null,
    };
  },
  methods: {
    search() {
      if (this.query) {
        const cleanQuery =
          this.query.trim().length > 0 ? this.query.trim() : null;
        this.$emit('search', cleanQuery);
      }
    },
    reset() {
      this.query = null;
      this.$emit('search', null);
    },
  },
};
</script>
