<template>
  <div class="form-group">
    <label :for="name" v-if="!withoutLabel">{{ label }}</label>
    <div class="form-control">
      <div class="py-4">
        <div
          class="rounded-full h-16 w-16 flex items-center justify-center bg-blue-700 text-white mx-auto"
          @click="openFileDialog"
        >
          <font-awesome-icon
            :icon="['fal', 'camera']"
            class="icon-md"
            size="2x"
          />
        </div>

        <p class="text-center mt-4 text-gray-500">
          {{ $t('form.pictureInput.instructions') }}
        </p>

        <p class="text-center mt-4 text-gray-800" v-if="pictureFile">
          {{ $t('form.pictureInput.newFile') }}
          <strong class="font-bold">{{ pictureFile.name }}</strong>
        </p>

        <input
          :id="name"
          class="hidden"
          type="file"
          ref="file"
          accept="image/*"
          @change="changePicture"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ModelInputMixin from '@/mixins/model-input';
import { getFiletoBase64 } from '@/shared/utils';

export default {
  name: 'PictureInput',
  mixins: [ModelInputMixin],
  data() {
    return { pictureFile: null };
  },
  methods: {
    async changePicture() {
      this.pictureFile = this.$refs.file.files[0];
      let fileData = await getFiletoBase64(this.pictureFile);
      this.changeValue({ name: this.pictureFile.name, base64: fileData });
    },
    openFileDialog() {
      this.$refs.file.click();
    },
  },
};
</script>
