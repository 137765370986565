<template>
  <main-layout>
    <template v-slot:title>
      {{ $t('admin.savedReplies.edit.title') }}
    </template>

    <template v-slot:actions>
      <destroy-button
        :text="$t('admin.savedReplies.edit.actions.destroy.text')"
        :buttonLabel="$t('admin.savedReplies.edit.actions.destroy.button')"
        @destroy="onDestroy"
        v-if="$p('savedReply', { ...savedReply, hasChildren }, 'destroy')"
      />
    </template>

    <saved-reply-form
      class="py-4 px-4"
      :savedReply="savedReply"
      :persist="onPersist"
      @change="onChange"
      v-if="savedReply"
    />
  </main-layout>
</template>

<script>
import merge from 'deepmerge';
import MainLayout from '@/components/admin/shared/main-layout';
import SavedReplyForm from './form';

export default {
  name: 'EditSavedReply',
  components: { MainLayout, SavedReplyForm },
  props: ['savedReplyId'],
  data() {
    return {
      savedReply: null,
      hasChildren: true,
    };
  },
  methods: {
    onChange(changes) {
      const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
      this.savedReply = merge.all(
        [this.savedReply, changes, { id: this.savedReplyId }],
        { arrayMerge: overwriteMerge }
      );
    },
    onPersist() {
      return this.services.savedReply.update(this.savedReply);
    },
    onDestroy() {
      this.services.savedReply
        .destroy(this.savedReply)
        .then(() => this.$router.push({ name: 'savedReplies' }));
    },
  },
  watch: {
    savedReplyId: {
      immediate: true,
      handler(savedReplyId) {
        this.$bind('savedReply', this.services.savedReply.find(savedReplyId));
        this.services.savedReply.hasChildren(savedReplyId).then(hasChildren => {
          this.hasChildren = hasChildren;
        });
      },
    },
  },
};
</script>
