<template>
  <main-layout>
    <template v-slot:title>
      {{ $t('admin.agents.edit.title') }}
    </template>

    <agent-form
      class="py-4 px-4"
      :agent="agent"
      :persist="onPersist"
      @change="onChange"
      v-if="agent"
    />
  </main-layout>
</template>

<script>
import merge from 'deepmerge';
import MainLayout from '@/components/admin/shared/main-layout';
import AgentForm from './form';

export default {
  name: 'EditAgent',
  components: { MainLayout, AgentForm },
  props: {
    agentId: { type: String, required: true },
  },
  data() {
    return { agent: null };
  },
  mounted() {
    this.agent = this.services.agent.build();
  },
  methods: {
    onChange(changes) {
      this.agent = merge(this.agent, changes);
    },
    onPersist() {
      const { newProfilePic, newSignatureUrl, ...agent } = this.agent;
      return this.services.agent.update(this.agentId, agent, newProfilePic, newSignatureUrl);
    },
  },
  watch: {
    agentId: {
      immediate: true,
      handler(agentId) {
        this.$bind('agent', this.services.agent.find(agentId)).then(
          attributes => {
            this.onChange(
              this.services.agent.enforceConsistentAttributes(attributes)
            );
          }
        );
      },
    },
  },
};
</script>
