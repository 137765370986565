import Vue from 'vue';
import { firestorePlugin } from 'vuefire';
import { serialize } from '@/shared/db';

let options = { serialize };

if (process.env.NODE_ENV === 'test') {
  options = { ...options, reset: false, wait: true };
}

Vue.use(firestorePlugin, options);
