import agentPolicy from './agent-policy';
import adminPolicy from './admin-policy';
import savedReplyPolicy from './saved-reply-policy';
import medicalReportLibraryPolicy from './medical-report-library-policy';
import agentGroupPolicy from './agent-group-policy';
import drugPolicy from './drug-policy';
import configPolicy from './config-policy';

export default {
  agentPolicy,
  adminPolicy,
  savedReplyPolicy,
  agentGroupPolicy,
  medicalReportLibraryPolicy,
  drugPolicy,
  configPolicy,
};
