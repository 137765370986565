import AlertsEN from './alerts.en.json';
import AlertsFR from './alerts.fr.json';
import AuthenticationEN from './authentication.en.json';
import AuthenticationFR from './authentication.fr.json';
import WorkspaceEN from './workspace.en.json';
import WorkspaceFR from './workspace.fr.json';
import ValidationEN from './validation.en.json';
import ValidationFR from './validation.fr.json';

export default {
  en: { ...AlertsEN, ...AuthenticationEN, ...WorkspaceEN, ...ValidationEN },
  fr: { ...AlertsFR, ...AuthenticationFR, ...WorkspaceFR, ...ValidationFR },
};
