export default {
  props: ['topicName'],
  data() {
    return { topic: null };
  },
  watch: {
    topicName: {
      immediate: true,
      handler(topicName) {
        this.topic = this.services.medicalReportLibrary.findTopic(
          topicName,
          this.contentLocale
        );
      },
    },
  },
};
