<template>
  <div class="form-group">
    <label :for="name" v-if="!withoutLabel">{{ label }}</label>
    <age-range-slider v-model="customValue" class="mt-2" />
  </div>
</template>

<script>
import ModelInputMixin from '@/mixins/model-input';

export default {
  name: 'AgeRangeInput',
  mixins: [ModelInputMixin],
  computed: {
    capitalizedAttributeName() {
      return (
        this.attributeName.charAt(0).toUpperCase() + this.attributeName.slice(1)
      );
    },
    minAttributeName() {
      return `min${this.capitalizedAttributeName}`;
    },
    maxAttributeName() {
      return `max${this.capitalizedAttributeName}`;
    },
    customValue: {
      get() {
        return [
          this.safeModel[this.minAttributeName] || 0,
          this.safeModel[this.maxAttributeName] || 216,
        ];
      },
      set(value) {
        this.$parent.$emit('change', {
          ...this.safeModel,
          [this.minAttributeName]: value[0],
          [this.maxAttributeName]: value[1],
        });
      },
    },
  },
};
</script>
