import services from '@/services';

const defaultState = {
  message: undefined,
  soundNotifications: localStorage.getItem('soundNotifications') !== 'false',
  config: undefined,
  groups: undefined,
  molecules: undefined,
  brandedDrugs: undefined,
  agent: undefined,
};
const defaultAuthenticatedState = services.authentication.loadInformation();

export default {
  ...defaultState,
  ...defaultAuthenticatedState,
  authenticated: false,
  appLoading: true,
};
