<template>
  <modal
    name="drug-preview-modal"
    width="650"
    height="auto"
    scrollable
    @opened="fetchPdfBase64"
    :clickToClose="false"
  >
    <div class="modal-content">
      <div class="modal-header border-0 py-6 px-6 flex items-center">
        <div class="modal-title flex items-center">
          <h3 class="font-bold text-lg mb-0">
            {{ $t(`${i18nScope}.title`) }}
          </h3>
        </div>
        <div class="ml-auto">
          <button
            type="button"
            class="btn text-gray-500 text-muted -mr-4"
            data-dismiss="modal"
            aria-label="Close"
            @click="close"
          >
            <font-awesome-icon :icon="['fal', 'times']" size="lg" />
          </button>
        </div>
      </div>
      <div class="modal-body pt-0 px-6">
        <div class="w-full bg-gray-100" style="height: 40rem">
          <iframe :src="pdfBase64" v-if="pdfBase64" class="w-full h-full" />
        </div>

        <div
          class="mt-6 text-center px-4 py-3 rounded-md bg-green-100 text-green-800"
          role="alert"
        >
          {{ $t(`${i18nScope}.text`) }}
        </div>

        <div class="py-6 text-right">
          <button type="button" class="btn btn-secondary" @click="close()">
            {{ $t(`${i18nScope}.closeButton`) }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'PrescriptionConfirmationModal',
  props: {
    brandedDrug: { type: Object, required: true },
    drug: { type: Object, required: true },
  },
  data() {
    return { pdfBase64: null, buttonState: 'default' };
  },
  computed: {
    i18nScope() {
      return 'admin.drugs.previewModal';
    },
  },
  methods: {
    async fetchPdfBase64() {
      this.pdfBase64 = await this.services.drug.getPdfPreviewUrl(
        this.brandedDrug.id,
        this.drug.id
      );
    },
    close() {
      this.pdfBase64 = null;
      this.$modal.hide('drug-preview-modal');
    },
  },
};
</script>
