<template>
  <div class="form-group">
    <label :for="name" v-if="!withoutLabel">{{ label }}</label>
    <select class="form-control" :id="name" :name="name" v-model="value">
      <slot></slot>
    </select>
  </div>
</template>

<script>
import ModelInputMixin from '@/mixins/model-input';

export default {
  name: 'SelectInput',
  mixins: [ModelInputMixin],
};
</script>
