<template>
  <div>
    <div v-for="savedReply in list" :key="savedReply.id">
      <div
        :class="{
          'hover:bg-gray-50': savedReply.id !== savedReplyId,
          'border-l-2 border-blue-700 bg-indigo-50 hover:bg-indigo-100':
            savedReply.id === savedReplyId,
        }"
      >
        <div
          class="cursor-pointer border-b border-gray-100"
          :style="{ paddingLeft: `${(savedReply.depth + 1) * 20}px` }"
        >
          <list-item :savedReply="savedReply" />
        </div>
      </div>

      <saved-reply-tree
        :list="savedReply.children"
        :savedReplyId="savedReplyId"
        v-if="!savedReply.leaf"
      />
    </div>
  </div>
</template>

<script>
import ListItem from './list-item';

export default {
  name: 'SavedReplyTree',
  components: { ListItem },
  props: {
    value: { type: Array, default: null },
    list: { type: Array, default: null },
    savedReplyId: { type: String, default: null },
  },
};
</script>
