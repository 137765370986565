<template>
  <div class="form-group">
    <label :for="name">{{ t('label') }}</label>

    <transition-group name="list" tag="div" class="ml-4">
      <div
        v-for="dosage in dosages"
        :key="dosage.id"
        class="py-6 border-b border-gray-100"
      >
        <array-item-nested-model-form
          v-model="value"
          :model="dosage"
          i18nScope="admin.drugs.form.dosages.form"
          class="pt-4"
        >
          <div class="relative">
            <text-input attributeName="name" :max-characters="300" />
            <div class="absolute" style="top: -1rem; right: 0">
              <destroy-button
                :text="t('destroy.text')"
                :buttonLabel="t('destroy.button')"
                @destroy="onRemove(dosage)"
              />
            </div>
          </div>

          <div class="grid grid-cols-2 gap-4 mt-6">
            <text-input attributeName="shortLabel" />
            <text-input attributeName="mobileLabel" />
          </div>
        </array-item-nested-model-form>
      </div>
    </transition-group>

    <div class="mt-6 text-right">
      <button
        type="button"
        class="btn-outline-primary btn-sm ml-auto"
        v-on:click.stop.prevent="onAdd"
        role="addDosage"
      >
        {{ t('addButton') }}
      </button>
    </div>
  </div>
</template>

<script>
import merge from 'deepmerge';
import ModelInputMixin from '@/mixins/model-input';

export default {
  name: 'DosagesInput',
  mixins: [ModelInputMixin],
  computed: {
    dosages() {
      return (this.value || []).filter(dosage => dosage.deleted !== true);
    },
  },
  methods: {
    onAdd() {
      this.value = [...this.value, this.services.drug.buildDosage()];
    },
    onRemove(dosage) {
      const index = this.value.findIndex(el => el.id === dosage.id);
      let newValue = this.value.slice();
      if (dosage.isNew) {
        newValue = [
          ...this.value.slice(0, index),
          ...this.value.slice(index + 1),
        ];
      } else {
        const updatedDosage = merge.all([{}, dosage, { deleted: true }]);
        newValue[index] = updatedDosage;
      }
      this.value = newValue;
    },
  },
};
</script>
