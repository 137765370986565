import merge from 'deepmerge';
import { getDB, nowOnServer } from '@/shared/db';
import {
  isBlank,
  isObjectEmpty,
  removeAccents,
  interpolate,
  htmlToMobileContent,
  getSupportedLanguage,
} from '@/shared/utils';
import { track as trackAgent } from './agent-tracking';
import i18n from '@/plugins/i18n';

export const previewMobileContentSample = (savedReply, locale) => {
  let interpolations = buildInterpolations(
    { firstName: 'Marion' }, // User
    { firstName: 'Lina', gender: 0 }, // Kid
    locale
  );
  return htmlToMobileContent(
    interpolate(savedReply.content[locale], interpolations)
  );
};

export const buildInterpolations = (user, kid, locale) => {
  return {
    userFirstName: user.firstName,
    kidFirstName: kid.firstName,
    kidGenderPronoun: i18n.t(
      `gender.pronoun.${kid.gender === 0 ? 'female' : 'male'}`,
      locale
    ),
    kidGenderArticle: i18n.t(
      `gender.article.${kid.gender === 0 ? 'female' : 'male'}`,
      locale
    ),
    kidGenderAgreement: kid.gender === 0 ? 'e' : '',
  };
};

const calculateDepth = (nodes, depth) => {
  nodes.forEach(node => {
    node.depth = depth;
    calculateDepth(node.children, depth + 1);
  });
};

const flattenTree = (nodes, rejectedId) => {
  return nodes
    .map(node => {
      if (node.id === rejectedId) return [];
      return [].concat(node, flattenTree(node.children, rejectedId));
    })
    .flat(42);
};

export const buildContentTree = (list, language, filters) => {
  const safeLanguage = getSupportedLanguage(language);
  const contentify = savedReply => {
    var clonedSavedReply = { id: savedReply.id, ...savedReply };
    clonedSavedReply.title = savedReply.title[safeLanguage];
    if (clonedSavedReply.leaf) {
      clonedSavedReply.image = savedReply.image[safeLanguage];
      clonedSavedReply.content = savedReply.content[safeLanguage];
      clonedSavedReply.content = htmlToMobileContent(
        interpolate(
          clonedSavedReply.content,
          buildInterpolations(
            { firstName: 'Marion' }, // User
            { firstName: 'Lina', gender: 0 }, // Kid
            language
          )
        )
      );
    }

    // don't filter categories (so only leaves)
    if (
      filters &&
      clonedSavedReply.leaf &&
      !acceptSavedReply(clonedSavedReply, filters)
    )
      return null;

    return clonedSavedReply;
  };

  return buildTree(list, contentify);
};

export const buildTree = (list, applyFunc) => {
  var memo = {};
  var root = [];

  // build the memo
  list.forEach(savedReply => {
    savedReply.children = [];
    memo[savedReply.id] = applyFunc ? applyFunc(savedReply) : savedReply;
  });

  // assign a parent to each saved reply
  list.forEach(savedReply => {
    if (!memo[savedReply.id]) return; // filtered or not
    if (savedReply.parentId) {
      var parent = memo[savedReply.parentId];
      if (parent) parent.children.push(memo[savedReply.id]);
    } else root.push(memo[savedReply.id]);
  });

  // Keep the searched categories and leafs
  if (applyFunc) {
    root = root.filter(function f(savedReply) {
      if (savedReply.leaf) return true;

      if (savedReply.children.length > 0) {
        return (savedReply.children = savedReply.children.filter(f)).length;
      }
    });
  }

  calculateDepth(root, 0);

  return root;
};

export const buildFlattenTree = (list, rejectedId) => {
  return flattenTree(buildTree(list), rejectedId);
};

export const acceptSavedReply = (savedReply, { query, showUnpublished }) => {
  const { title, content } = savedReply;

  const matchWordInContent = new RegExp(`\\b${query}\\b`);

  return (
    (showUnpublished || savedReply.published) &&
    (!query ||
      title.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
      matchWordInContent.test(content))
  );
};

export const build = attributes => {
  return merge(
    {
      title: { fr: '', en: '' },
      content: { fr: '', en: '' },
      image: { fr: '', en: '' },
      isImage: false,
      leaf: true,
      published: false,
      parentId: null,
      position: 99,
      agentGroupIds: ['ALL'],
    },
    attributes || {}
  );
};

export const filter = (list, groupIds) => {
  if (list === null) return null;
  let allGroupIds = ['all', 'ALL', ...groupIds];
  return list.filter(savedReply => {
    let requestedGroupIds = isBlank(savedReply.agentGroupIds)
      ? ['ALL']
      : savedReply.agentGroupIds;
    return requestedGroupIds.some(groupId => allGroupIds.includes(groupId));
  });
};

export const findAll = (locale, withTracking) => {
  if (withTracking) trackAgent('VIEW', 'SAVED_REPLIES');
  return getDB()
    .collection('saved-replies')
    .orderBy(`title.${locale}`, 'asc');
};

export const findAllRoots = locale => {
  return getDB()
    .collection('saved-replies')
    .where('leaf', '==', false)
    .orderBy(`title.${locale}`, 'asc');
};

export const find = id => {
  return getDB()
    .collection('saved-replies')
    .doc(id);
};

export const hasChildren = id => {
  return getDB()
    .collection('saved-replies')
    .where('parentId', '==', id)
    .get()
    .then(snapshot => !snapshot.empty);
};

export const create = savedReply => {
  trackAgent('CREATE', 'SAVED_REPLY', savedReply.title?.fr);
  return getDB()
    .collection('saved-replies')
    .add({ ...savedReply, createdAt: nowOnServer() });
};

export const update = savedReply => {
  trackAgent('UPDATE', 'SAVED_REPLY', savedReply.id);
  const savedReplyDoc = getDB()
    .collection('saved-replies')
    .doc(savedReply.id);
  return savedReplyDoc.update({ ...savedReply, updatedAt: nowOnServer() });
};

export const destroy = savedReply => {
  trackAgent('DESTROY', 'SAVED_REPLY', savedReply.id);
  return getDB()
    .collection('saved-replies')
    .doc(savedReply.id)
    .delete();
};

const recursiveSearch = (node, regexp, locale) => {
  if (node.children && node.children.length > 0 && !!node.title[locale]) {
    if (removeAccents(node.title[locale]).match(regexp)) return node.children;
    else
      return node.children.map(child => recursiveSearch(child, regexp, locale));
  } else if (!!node.content[locale] && !!node.title[locale])
    return removeAccents(node.content[locale]).match(regexp) ||
      removeAccents(node.title[locale]).match(regexp)
      ? node
      : null;
};

export const search = (nodes, query, locale) => {
  const regexp = new RegExp(`${removeAccents(query)}`, 'i');
  return nodes
    .map(node =>
      node.children.map(node => recursiveSearch(node, regexp, locale))
    )
    .flat(42)
    .filter(node => node && node.leaf && node.published && node.title[locale])
    .sort((n1, n2) => n1.title[locale].localeCompare(n2.title[locale]));
};

export const recursiveUpdateTree = (parent, nodes, batch) => {
  nodes.forEach((node, index) => {
    const doc = getDB()
      .collection('saved-replies')
      .doc(node.id);
    var changes = {};

    if (node.position !== index) changes.position = index;

    if (parent && parent.id !== node.parentId) changes.parentId = parent.id;

    if (!isObjectEmpty(changes)) batch.update(doc, changes);

    recursiveUpdateTree(node, node.children, batch);
  });
};

export const updateTree = newTree => {
  var batch = getDB().batch();

  recursiveUpdateTree(null, newTree, batch);

  // update the whole tree with a single call to Firebase
  return batch.commit();
};

export const isValid = savedReply => {
  if (!savedReply) return false;
  return savedReply.title?.fr?.length > 0;
};
