<template>
  <div class="grid grid-cols-2 gap-4">
    <div class="flex items-center justify-between">
      <label
        class="text-gray-700 font-semibold"
        for="saved-reply-is-category"
        >{{ $t('admin.savedReplies.form.isCategory') }}</label
      >
      <div class="relative checkbox-input">
        <input
          type="checkbox"
          class="sr-only"
          id="saved-reply-is-category"
          v-model="isCategoryInput"
        />
        <label for="saved-reply-is-category">
          <div
            class="block bg-gray-100 w-8 h-5 rounded-full transition-colors duration-300"
          ></div>
          <div
            class="dot absolute left-1 top-1 bg-gray-300 w-3 h-3 rounded-full transition-colors duration-300"
          ></div>
        </label>
      </div>
    </div>

    <div class="row-span-3">
      <div class="form-group">
        <label for="saved-reply-parent">{{
          $t('admin.savedReplies.form.parentId')
        }}</label>
        <select
          class="form-control"
          id="saved-reply-parent"
          v-model="parentInput"
        >
          <option value="">{{ $t('admin.savedReplies.form.noParent') }}</option>
          <option
            v-for="parent in parentsTree"
            :key="parent.id"
            :value="parent.id"
          >
            {{ parentOptionName(parent) }}
          </option>
        </select>
      </div>
    </div>

    <div class="flex items-center justify-between" v-if="savedReply.leaf">
      <label class="text-gray-700 font-semibold" for="saved-reply-is-image">{{
        $t('admin.savedReplies.form.isImage')
      }}</label>
      <div class="relative checkbox-input">
        <input
          type="checkbox"
          class="sr-only"
          id="saved-reply-is-image"
          v-model="isImageInput"
        />
        <label for="saved-reply-is-image">
          <div
            class="block bg-gray-100 w-8 h-5 rounded-full transition-colors duration-300"
          ></div>
          <div
            class="dot absolute left-1 top-1 bg-gray-300 w-3 h-3 rounded-full transition-colors duration-300"
          ></div>
        </label>
      </div>
    </div>

    <div class="flex items-center justify-between">
      <label class="text-gray-700 font-semibold" for="saved-reply-published">{{
        $t('admin.savedReplies.form.published')
      }}</label>
      <div class="relative checkbox-input">
        <input
          type="checkbox"
          class="sr-only"
          id="saved-reply-published"
          v-model="publishedInput"
        />
        <label for="saved-reply-published">
          <div
            class="block bg-gray-100 w-8 h-5 rounded-full transition-colors duration-300"
          ></div>
          <div
            class="dot absolute left-1 top-1 bg-gray-300 w-3 h-3 rounded-full transition-colors duration-300"
          ></div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SavedReplyPropertiesForm',
  props: {
    savedReply: { type: Object },
  },
  data() {
    return {
      parents: [],
      parentsTree: [],
    };
  },
  mounted() {
    this.$bind(
      'parents',
      this.services.savedReply.findAllRoots(this.contentLocale)
    );
  },
  computed: {
    isCategoryInput: {
      get() {
        return !this.savedReply.leaf;
      },
      set(value) {
        this.$emit('change', { leaf: !value });
      },
    },
    publishedInput: {
      get() {
        return this.savedReply.published;
      },
      set(value) {
        this.$emit('change', { published: value });
      },
    },
    parentInput: {
      get() {
        return this.savedReply.parentId;
      },
      set(value) {
        this.$emit('change', { parentId: value });
      },
    },
    isImageInput: {
      get() {
        return this.savedReply.isImage;
      },
      set(value) {
        this.$emit('change', { isImage: value });
      },
    },
  },
  methods: {
    parentOptionName(parent) {
      var prefix = '';
      for (var i = 0; i < parent.depth; i++) {
        prefix = `--${prefix}`;
      }
      return `${prefix} ${parent.title[this.contentLocale]}`;
    },
  },
  watch: {
    parents() {
      this.parentsTree = this.services.savedReply.buildFlattenTree(
        this.parents,
        this.savedReply.id
      );
    },
  },
};
</script>
