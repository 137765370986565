<template>
  <main-layout>
    <template v-slot:title>
      <span v-if="drug">{{
        $t(`${i18nScope}.title`, { name: brandedDrug.name })
      }}</span>
    </template>

    <template v-slot:actions>
      <button
        type="button"
        class="btn-sm btn-outline-primary"
        :class="{ 'cursor-default': isModified }"
        @click="openPreviewModal"
        :disabled="isModified"
      >
        {{ $t(`${i18nScope}.previewButton`) }}
      </button>
    </template>

    <drug-form
      class="py-4 px-4"
      :key="drug.id"
      :drug="drug"
      :persist="onPersist"
      @change="onChange"
      v-if="drug"
    />

    <preview-modal
      :brandedDrug="brandedDrug"
      :drug="drug"
      v-on="$listeners"
      v-if="drug"
    />
  </main-layout>
</template>

<script>
import { mapActions } from 'vuex';
import merge from 'deepmerge';
import MainLayout from '@/components/admin/shared/main-layout';
import DrugForm from './form';
import PreviewModal from './preview-modal';

export default {
  name: 'EditDrug',
  components: { MainLayout, DrugForm, PreviewModal },
  props: ['brandedDrugId', 'drugId'],
  data() {
    return {
      brandedDrug: null,
      drug: null,
      isModified: false,
    };
  },
  created() {
    this.$watch(
      vm => (vm.brandedDrugId, vm.cleanDrugId, Date.now()),
      () => this.fetchDrug(),
      { immediate: true }
    );
  },
  computed: {
    i18nScope() {
      return 'admin.drugs.edit';
    },
    cleanDrugId() {
      return this.drugId.replace('%2F', '/');
    },
  },
  methods: {
    ...mapActions(['updateDrug']),
    onChange(changes) {
      this.isModified = true;
      const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
      this.drug = merge.all([this.drug, changes, { id: this.cleanDrugId }], {
        arrayMerge: overwriteMerge,
      });
    },
    onPersist() {
      return this.updateDrug({
        brandedDrugId: this.brandedDrugId,
        drug: this.drug,
      }).then(drug => {
        this.drug = drug;
        this.isModified = false;
      });
    },
    async fetchDrug() {
      this.brandedDrug = await this.services.brandedDrug.find(
        this.brandedDrugId
      );
      this.drug = this.services.drug.find(this.brandedDrug, this.cleanDrugId);

      // either the branded drug or the drug doesn't exist
      if (!this.brandedDrug || !this.drug)
        this.$router.push({ name: 'brandedDrugs' });
    },
    openPreviewModal() {
      this.$modal.show(`drug-preview-modal`);
    },
  },
};
</script>
