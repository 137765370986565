<template>
  <div>
    <div
      v-for="agent in list"
      :key="agent.id"
      class="list-group-item cursor-pointer"
      :class="{
        'hover:bg-gray-50': agent.id !== agentId,
        'border-l-2 border-blue-700 bg-indigo-50 hover:bg-indigo-100':
          agent.id === agentId,
      }"
    >
      <div class="cursor-pointer border-b border-gray-100 px-4">
        <agent :agent="agent" />
      </div>
    </div>
  </div>
</template>

<script>
import Agent from './agent';

export default {
  name: 'AgentList',
  components: { Agent },
  props: {
    list: { type: Array, default: null },
    agentId: { type: String, default: null },
  },
};
</script>
