<template>
  <div>
    <div class="container-fluid min-height" :class="{ 'd-none': !isAnimating }">
      <div
        class="d-flex flex-column align-items-center justify-content-center min-height"
      >
        <Lottie
          :options="animationOptions"
          :height="size"
          :width="size"
          v-on:animCreated="registerAnimation"
        />
      </div>
    </div>
    <slot v-if="!isAnimating"></slot>
  </div>
</template>

<script>
import Lottie from 'vue-lottie';
import lightAnimationData from '@/assets/animations/spinner-light.json';
import darkAnimationData from '@/assets/animations/spinner-dark.json';

export default {
  name: 'Loader',
  components: { Lottie },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    onComplete: {
      type: Function,
      default: () => {},
    },
    size: {
      type: Number,
      default: 50,
    },
    inverseColor: {
      type: Boolean,
      default: false,
    },
    waitForLoop: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      animation: null,
      isAnimating: false,
    };
  },
  computed: {
    animationData() {
      return this.inverseColor ? darkAnimationData : lightAnimationData;
    },
    animationOptions() {
      return {
        animationData: this.animationData,
        autoplay: false,
        loop: true,
      };
    },
  },
  methods: {
    registerAnimation(animation) {
      this.animation = animation;
      this.animation.setSpeed(1.25);
      this.animation.onLoopComplete = this.onLoopComplete.bind(this);
      if (this.isAnimating) this.animation.play(); // very first call to the play function
    },
    onLoopComplete() {
      // the loading won't stop at the middle of an animation loop
      if (!this.isLoading && this.isAnimating) {
        this.isAnimating = false;
        this.animation.stop();
        this.onComplete();
      }
    },
  },
  watch: {
    isLoading: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.isAnimating = newValue;
          this.animation?.goToAndPlay(0, false); // restart the animation
        } else if (!this.waitForLoop) {
          this.isAnimating = false;
        }
      },
    },
  },
};
</script>
