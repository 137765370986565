import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import store from '@/store';
import services from '@/services';
import { isBlank, hashCode } from '@/shared/utils';
import { APP_REVISION, IS_PRODUCTION } from '@/shared/config';
import { AVAILABLE_LOCALES, getLastKnownUiLocale } from '@/plugins/i18n';
import {
  SYSTEM_ADMINISTRATORS,
  SAVED_REPLIES_ADMINISTRATORS,
  DRUGS_ADMINISTRATORS,
  MEDICAL_REPORT_ADMINISTRATORS,
} from '@/services/agent-group';

const mixin = {
  computed: {
    ...mapState(['appLoading', 'authenticated', 'sidebarDisplayed']),
    uiLocale() {
      return (
        this.currentAgent?.locale ||
        getLastKnownUiLocale() ||
        AVAILABLE_LOCALES[0]
      );
    },
    contentLocale() {
      return 'fr';
    },
    allLocales() {
      return AVAILABLE_LOCALES;
    },
    currentAgent() {
      return store.state.agent;
    },
    isCurrentAgentAdmin() {
      return this.currentAgentGroups.some(group =>
        [
          SYSTEM_ADMINISTRATORS,
          SAVED_REPLIES_ADMINISTRATORS,
          DRUGS_ADMINISTRATORS,
          MEDICAL_REPORT_ADMINISTRATORS,
        ].includes(group.name)
      );
    },
    isCurrentAgentBetaTester() {
      return this.currentAgentGroups.some(
        group => group.name === 'BetaTesters'
      );
    },
    currentAgentGroups() {
      return (store.state.groups || []).filter(group => {
        return group.agentIds?.indexOf(this.currentAgent?.id) >= 0;
      });
    },
    currentAgentGroupIds() {
      return this.currentAgentGroups.map(group => group.id);
    },
    currentAppVersion() {
      return store.state.config?.version;
    },
    localAppRevision() {
      return APP_REVISION;
    },
    isProduction() {
      return IS_PRODUCTION;
    },
    logoURL() {
      if (this.isProduction) return require('@/assets/logo-blue.svg');
      else return require('@/assets/logo-black.svg');
    },
    bilobaAvatarUrl() {
      if (this.isProduction) return require('@/assets/logo-blue.svg');
      else return require('@/assets/logo-black.svg');
    },
    services() {
      return services;
    },
  },
  methods: {
    ...mapActions(['signOutAction']),
    isBlank(object) {
      return isBlank(object);
    },
    buildCompositeKey() {
      var args = Array.prototype.slice.call(arguments);
      return hashCode(args.flat().join('/'));
    },
    reloadPage() {
      window.location.reload(true);
    },
    forceSignOut() {},
  },
};

Vue.mixin(mixin);

export default mixin;
