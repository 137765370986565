<template>
  <router-link
    v-if="$p(policyScope, null, 'show')"
    :to="{ name: routeName }"
    v-slot="{ href, navigate, isActive }"
  >
    <a
      :href="href"
      @click="navigate"
      class="group flex items-center px-5 py-4 font-medium transition-colors"
      :class="{
        'bg-indigo-50 hover:bg-indigo-100': isActive,
        'hover:bg-gray-50': !isActive,
      }"
    >
      <div class="w-8 mr-2">
        <font-awesome-icon :icon="icon" size="lg" />
      </div>
      <span>{{ label }}</span>
    </a>
  </router-link>
</template>

<script>
export default {
  name: 'AdminNavbarItem',
  props: ['policyScope', 'routeName', 'labelKey', 'icon'],
  computed: {
    label() {
      return this.$t(`admin.navbar.links.${this.labelKey}`);
    },
  },
};
</script>
