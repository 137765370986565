<template>
  <div v-if="hasItems">
    <div class="cursor-pointer bg-gray-100 sticky top-0" @click="open = !open">
      <div
        class="flex justify-between items-center py-5 px-4 border-b border-gray-100"
      >
        <h3 class="ml-3 font-semibold">
          {{ $t(`admin.agents.list.${title}`) }}
        </h3>
        <font-awesome-icon
          :icon="['fal', open ? 'chevron-up' : 'chevron-down']"
        />
      </div>
    </div>
    <collapse-transition>
      <list
        :agentId="agentId"
        :class="{ 'list-group': true }"
        :list="list"
        v-if="open"
      />
    </collapse-transition>
  </div>
</template>

<script>
import List from './index';

export default {
  name: 'AgentsCategory',
  components: { List },
  props: ['title', 'agentId', 'list'],
  data() {
    return {
      open: true,
    };
  },
  computed: {
    hasItems() {
      return this.list.length;
    },
  },
  watch: {
    list() {
      if (this.hasItems) this.open = true;
    },
  },
};
</script>
