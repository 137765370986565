<template>
  <main-layout>
    <template v-slot:title>
      <font-awesome-icon :icon="topic.icon" class="mr-3" />
      <span>{{ title }}</span>
      <span class="mx-2">/</span>
      <span>{{ $t('admin.medicalReportLibrary.edit.title') }}</span>
    </template>

    <template v-slot:actions>
      <destroy-button
        :text="$t('admin.medicalReportLibrary.edit.actions.destroy.text')"
        :buttonLabel="
          $t('admin.medicalReportLibrary.edit.actions.destroy.button')
        "
        @destroy="onDestroy"
        v-if="
          $p('medicalReportLibrary', { ...libraryItem, hasChildren }, 'destroy')
        "
      />
    </template>

    <library-item-form
      class="py-4 px-4"
      :topic="topic"
      :libraryItem="libraryItem"
      :persist="onPersist"
      @change="onChange"
      v-if="libraryItem"
    />
  </main-layout>
</template>

<script>
import MedicalReportLibraryMixin from '@/mixins/medical-report-library';
import MainLayout from '@/components/admin/shared/main-layout';
import LibraryItemForm from './form';

export default {
  name: 'EditMedicalReportHistoryItem',
  mixins: [MedicalReportLibraryMixin],
  components: { MainLayout, LibraryItemForm },
  props: ['libraryItemId'],
  data() {
    return {
      libraryItem: null,
      hasChildren: true,
    };
  },
  computed: {
    title() {
      return this.topic.title[this.contentLocale];
    },
  },
  methods: {
    onChange(changes) {
      this.libraryItem = this.services.medicalReportLibrary.applyChanges(
        this.libraryItem,
        { id: this.libraryItemId, ...changes }
      );
    },
    onPersist() {
      return this.services.medicalReportLibrary.update(
        this.topic.name,
        this.libraryItem
      );
    },
    onDestroy() {
      this.services.medicalReportLibrary.destroy(
        this.topic.name,
        this.libraryItem
      );
    },
  },
  watch: {
    libraryItemId: {
      immediate: true,
      handler(libraryItemId) {
        this.$bind(
          'libraryItem',
          this.services.medicalReportLibrary.find(this.topicName, libraryItemId)
        );
        this.services.medicalReportLibrary
          .hasChildren(this.topic.name, libraryItemId)
          .then(hasChildren => {
            this.hasChildren = hasChildren;
          });
      },
    },
  },
};
</script>
