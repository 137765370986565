<template>
  <div :class="{ 'mb-4 mr-4': item, 'flex-basis-100': !item }">
    <dropdown
      color="dark"
      buttonCustomClass="btn btn-outline-dark flex flex-wrap-0"
      v-if="item && canBeHidden && !selected"
      ref="dropdown"
    >
      <template v-slot:button>
        <div class="flex items-center">
          <font-awesome-icon :icon="['fad', 'eye-slash']" class="mr-3" />
          <span class="font-bold">{{ item.title }}</span>
          <severity-badge :severity="item.severity" class="ml-3" />
        </div>
      </template>

      <div class="flex justify-around py-3 px-4">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          @click="() => select(false)"
        >
          <font-awesome-icon :icon="['fad', 'eye']" class="mr-3" />
          <span>{{ $t(`${i18nScope}.canBeHidden.visible`) }}</span>
        </button>
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          @click="() => select(true)"
        >
          <font-awesome-icon :icon="['fad', 'eye-slash']" class="mr-3" />
          <span>{{ $t(`${i18nScope}.canBeHidden.hidden`) }}</span>
        </button>
      </div>
    </dropdown>

    <button
      class="btn flex items-center"
      :class="{
        'btn-outline-dark': !selected,
        'border bg-black text-white': selected,
      }"
      type="button"
      @click="() => select(false)"
      v-if="item && (selected || !canBeHidden)"
    >
      <font-awesome-icon
        :icon="['fad', 'eye-slash']"
        class="mr-3"
        v-if="canBeHidden && isHidden"
      />
      <font-awesome-icon
        :icon="['fad', 'eye']"
        class="mr-3"
        v-if="canBeHidden && !isHidden"
      />
      <span class="font-bold">{{ item.title }}</span>
      <severity-badge :severity="item.severity" class="ml-3" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'MedicalReportPickerListItem',
  props: {
    item: { type: Object, default: null },
    selected: { type: Boolean, default: false },
  },
  data() {
    return { isHidden: false };
  },
  mounted() {
    this.isHidden = !!this.item.hidden;
  },
  computed: {
    canBeHidden() {
      return !!this.item.canBeHidden;
    },
    i18nScope() {
      return 'medicalReport.picker.listItem';
    },
  },
  methods: {
    select(hidden) {
      this.isHidden = hidden;
      this.closeDropdown();
      this.$emit(
        'select',
        { ...this.item, hidden, canBeHidden: this.canBeHidden },
        this.selected
      );
    },
    closeDropdown() {
      if (this.$refs.dropdown) this.$refs.dropdown.closeDropdown();
    },
  },
};
</script>
