<template>
  <main-layout>
    <template v-slot:title>
      {{ $t('admin.savedReplies.new.title') }}
    </template>

    <saved-reply-form
      class="py-4 px-4"
      :savedReply="savedReply"
      :persist="onPersist"
      @change="onChange"
      v-if="savedReply"
    />
  </main-layout>
</template>

<script>
import merge from 'deepmerge';
import MainLayout from '@/components/admin/shared/main-layout';
import SavedReplyForm from './form';

export default {
  name: 'NewSavedReply',
  components: { MainLayout, SavedReplyForm },
  data() {
    return { savedReply: null };
  },
  methods: {
    onChange(changes) {
      const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
      this.savedReply = merge(this.savedReply, changes, {
        arrayMerge: overwriteMerge,
      });
    },
    onPersist() {
      return this.services.savedReply
        .create(this.savedReply)
        .then(savedReply => {
          this.$router.push({
            name: 'editSavedReply',
            params: { savedReplyId: savedReply.id },
          });
        });
    },
  },
  watch: {
    services: {
      immediate: true,
      handler() {
        this.savedReply = this.services.savedReply.build();
      },
    },
  },
};
</script>
