<template>
  <div class="px-6 overflow-auto" style="height: 24rem">
    <div
      v-for="(category, index) in notEmptyCategories"
      :key="`antecedent-category-${index}`"
      class="mb-4"
    >
      <h3 class="text-md text-gray-700">{{ category.title }}</h3>

      <library-item-list
        class="mt-3"
        :topic="topic"
        :list="category.children"
        :selectedItems="selectedItems"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import LibraryItemList from './list';

export default {
  name: 'MedicalReportPickerCategories',
  components: { LibraryItemList },
  props: {
    topic: { type: Object, required: true },
    categories: { type: Array, required: true },
    selectedItems: { type: Array, required: true },
  },
  computed: {
    notEmptyCategories() {
      return this.categories.filter(category => category.children.length > 0);
    },
  },
};
</script>
