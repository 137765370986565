<template>
  <div v-show="severity > 2">
    <font-awesome-icon :icon="['fad', 'heartbeat']" :color="color" />
  </div>
</template>

<script>
export default {
  name: 'SeverityBadge',
  props: {
    severity: { type: Number, default: 0 },
  },
  computed: {
    color() {
      return this.services.medicalReportLibrary.getSeverityColor(
        this.severity,
        this.severity
      );
    },
  },
};
</script>
