import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/database';
import { FIREBASE_CONFIG } from '@/shared/config';

let app = null;
let db = null;

export const firebaseTestHost = process.env.VUE_APP_FIRESTORE_EMULATOR_HOST;

export const getApp = () => {
  if (!app) {
    app = firebase.initializeApp(FIREBASE_CONFIG);
  }
  return app;
};
export const getDB = () => {
  if (!db) {
    db = getApp().firestore();
    if (process.env.NODE_ENV === 'test') {
      db.settings({ host: firebaseTestHost, ssl: false });
    }
  }
  return db;
};

export const serialize = snapshot => {
  // snapshot.data() DOES NOT contain the `id` of the document. By
  // default, Vuefire adds it as a non enumerable property named id.
  // This allows to easily create copies when updating documents, as using
  // the spread operator won't copy it
  return { id: snapshot.id, ...snapshot.data() };
};

export const buildAgent = agent => {
  if (!agent) return null;

  var _agent = { id: agent.id, ...agent };

  // remove undefined attributes
  Object.keys(_agent).forEach(key =>
    _agent[key] === undefined ? delete _agent[key] : {}
  );

  return _agent;
};

export const authenticateDb = async token => {
  return firebase
    .auth()
    .signInWithCustomToken(token)
    .then(() => {
      setupOnlinePresence();
      return true;
    })
    .catch(error => {
      console.log(
        'DB Auth ERROR',
        'code =',
        error.code,
        ', message = ',
        error.message
      );
      return null;
    });
};

export const signOutFromDb = async () => {
  finishOnlinePresence();
  return firebase.auth().signOut();
};

// Others

export const nowOnServer = () => {
  return firebase.firestore.FieldValue.serverTimestamp();
};

export const fromDate = date => {
  return firebase.firestore.Timestamp.fromMillis(date);
};

export const findAppConfig = () => {
  return getDB()
    .collection('configs')
    .doc('desk');
};

// NOTE: https://cloud.google.com/firestore/docs/solutions/presence
export const setupOnlinePresence = () => {
  var uid = firebase.auth().currentUser.uid;

  // Create a reference to this user's specific status node.
  // This is where we will store data about being online/offline.
  var userStatusDatabaseRef = firebase.database().ref('/status/' + uid);

  // We'll create two constants which we will write to
  // the Realtime database when this device is offline
  // or online.
  var isOfflineForDatabase = {
    state: 'offline',
    lastChanged: firebase.database.ServerValue.TIMESTAMP,
  };

  var isOnlineForDatabase = {
    state: 'online',
    lastChanged: firebase.database.ServerValue.TIMESTAMP,
  };

  // Create a reference to the special '.info/connected' path in
  // Realtime Database. This path returns `true` when connected
  // and `false` when disconnected.
  firebase
    .database()
    .ref('.info/connected')
    .on('value', function(snapshot) {
      // If we're not currently connected, don't do anything.
      if (snapshot.val() == false) {
        return;
      }

      // If we are currently connected, then use the 'onDisconnect()'
      // method to add a set which will only trigger once this
      // client has disconnected by closing the app,
      // losing internet, or any other means.
      userStatusDatabaseRef
        .onDisconnect()
        .set(isOfflineForDatabase)
        .then(function() {
          // The promise returned from .onDisconnect().set() will
          // resolve as soon as the server acknowledges the onDisconnect()
          // request, NOT once we've actually disconnected:
          // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

          // We can now safely set ourselves as 'online' knowing that the
          // server will mark us as offline once we lose connection.
          userStatusDatabaseRef.set(isOnlineForDatabase);
        });
    });
};

export const finishOnlinePresence = () => {
  var uid = firebase.auth().currentUser.uid;
  var userStatusDatabaseRef = firebase.database().ref('/status/' + uid);
  var isOfflineForDatabase = {
    state: 'offline',
    lastChanged: firebase.database.ServerValue.TIMESTAMP,
  };
  userStatusDatabaseRef.set(isOfflineForDatabase);
};
