import services from '@/services';

export const getAgentFullName = state =>
  services.agent.getFullName(state.agent);

export const getAgentWithGroups = state => {
  const { agent, groups } = state;
  if (agent) {
    const agentGroups = (groups || [])
      .filter(group => group.agentIds.indexOf(agent.id) !== -1)
      .map(group => group.name);
    return { ...agent, groups: agentGroups };
  } else return null;
};

export const getGroupByName = ({ groups }) => name => {
  return groups.find(group => group.name === name);
};
