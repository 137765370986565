<template>
  <model-form
    :model="config"
    i18nScope="admin.config.form"
    :validate="services.config.isValid"
    :on-submit="persist"
    v-on="$listeners"
  >
    <div class="grid grid-cols-2 gap-4">
      <checkbox-input attributeName="almostDrowning" />
      <div>&nbsp;</div>
      <checkbox-input attributeName="drowning" />
      <div>&nbsp;</div>
    </div>
  </model-form>
</template>

<script>
export default {
  name: 'ConfigForm',
  props: {
    config: { type: Object, required: true },
    persist: { type: Function, default: () => {} },
  },
};
</script>
