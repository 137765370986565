<template>
  <div class="flex items-stretch">
    <select class="form-control" v-model="newAgentId">
      <option
        v-for="agent in filteredAgents"
        :key="agent.id"
        :value="agent.id"
        >{{ agentOptionLabel(agent) }}</option
      >
    </select>

    <div class="flex-1 ml-3">
      <button
        class="btn btn-outline-primary h-full"
        v-on:click.stop.prevent="$emit('on-add', newAgentId)"
      >
        {{ $t('admin.agentGroups.form.agentsInput.add') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AgentsInputAddListItem',
  props: {
    selectedAgentIds: { type: Array, required: true },
  },
  data() {
    return { agents: [], newAgentId: null };
  },
  mounted() {
    this.$bind('agents', this.services.agent.findAll());
  },
  computed: {
    filteredAgents() {
      return this.services.agent.sortByDisabledLast(
        this.services.agent.filter(this.agents, {
          excludeIds: this.selectedAgentIds,
        })
      );
    },
  },
  methods: {
    agentOptionLabel(agent) {
      const { agentFullName, agentTitle } = this.$options.filters;
      const label = `${agentFullName(agent)} (${agentTitle(agent)})`;
      return agent.isDisabled
        ? this.$i18n.t(
            `admin.agentGroups.form.agentsInput.isDisabledOption.${agent.gender}`,
            { label }
          )
        : label;
    },
  },
};
</script>
