<template>
  <sidebar-layout>
    <template v-slot:title>
      <font-awesome-icon :icon="['fad', 'user-friends']" class="mr-3" />
      <span>{{ $t('admin.agentGroups.list.title') }}</span>
    </template>

    <template v-slot:actions>
      <router-link
        :to="{ name: 'newAgentGroup' }"
        class="btn-sm btn-outline-primary"
      >
        <font-awesome-icon :icon="['fas', 'plus']" />
      </router-link>
    </template>

    <list
      :agentGroupId="agentGroupId"
      :class="{ 'list-group': true }"
      :list="list"
      v-if="list"
    />
  </sidebar-layout>
</template>

<script>
import SidebarLayout from '@/components/admin/shared/sidebar-layout';
import List from './list';

export default {
  name: 'agentGroupsList',
  components: { SidebarLayout, List },
  props: ['agentGroupId'],
  data() {
    return {
      list: null,
    };
  },
  mounted() {
    this.$bind('list', this.services.agentGroup.findAll(true));
  },
};
</script>
