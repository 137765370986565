<template>
  <div class="h-screen flex overflow-hidden bg-white">
    <div
      v-if="appLoading"
      class="flex items-center justify-center w-full h-full"
    >
      <loader :isLoading="true" :size="75" />
    </div>

    <div
      class="flex lg:hidden items-center justify-center w-full h-full"
      v-else
    >
      <div>
        <div class="text-center mb-10">
          <img
            :src="logoURL"
            class="mx-auto fill-primary"
            data-inject-svg
            alt
            style="height: 76px;"
          />
        </div>

        <div class="alert alert-secondary mx-4" role="alert">
          <h4 class="alert-heading">{{ $t('disabledOnMobile.title') }}</h4>
          <div>
            {{ $t('disabledOnMobile.body') }}
          </div>
        </div>
      </div>
    </div>

    <div class="hidden lg:block" v-if="!appLoading">
      <router-view />
    </div>
  </div>
</template>

<script>
import { setUiLocale } from '@/plugins/i18n';

export default {
  name: 'App',
  watch: {
    authenticated(newValue) {
      if (this.appLoading && !newValue) {
        // force the browser to display the signIn page
        this.reloadPage();
      }
    },
    uiLocale: {
      immediate: true,
      handler(newLocale) {
        setUiLocale(newLocale);
      },
    },
    isCurrentAgentAdmin(isAdmin) {
      if (this.appLoading || !this.authenticated) return;
      if (!isAdmin) this.signOutAction();
    },
  },
};
</script>

<style lang="scss">
@import '@/design/application.scss';
</style>
