<template>
  <nav class="mt-8 flex-1 bg-white">
    <navbar-item
      routeName="savedReplies"
      :icon="['fal', 'rocket']"
      labelKey="savedReplies"
      policyScope="savedReply"
    />

    <navbar-item
      routeName="agents"
      :icon="['fad', 'user-md']"
      labelKey="agents"
      policyScope="agent"
    />

    <navbar-item
      routeName="agentGroups"
      :icon="['fad', 'user-friends']"
      labelKey="agentGroups"
      policyScope="agentGroup"
    />

    <navbar-item
      routeName="medicalReportLibrary"
      :icon="['fad', 'book-medical']"
      labelKey="medicalReportLibrary"
      policyScope="medicalReportLibrary"
    />

    <navbar-item
      routeName="molecules"
      :icon="['fad', 'dna']"
      labelKey="molecules"
      policyScope="drug"
    />

    <navbar-item
      routeName="brandedDrugs"
      :icon="['fad', 'pills']"
      labelKey="brandedDrugs"
      policyScope="drug"
    />

    <navbar-item
      routeName="config"
      :icon="['fad', 'sliders-v-square']"
      labelKey="config"
      policyScope="config"
    />
  </nav>
</template>

<script>
import NavbarItem from './navbar-item';

export default {
  name: 'AdminNavbar',
  components: { NavbarItem },
};
</script>
