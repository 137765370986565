import {
  SYSTEM_ADMINISTRATORS,
  SAVED_REPLIES_ADMINISTRATORS,
  DRUGS_ADMINISTRATORS,
  MEDICAL_REPORT_ADMINISTRATORS,
} from '@/services/agent-group';

export default {
  isSignedIn() {
    return !!this.user;
  },
  isAdmin() {
    return (
      this.isSystemAdmin() ||
      this.isSavedRepliesAdmin() ||
      this.isDrugsAdmin() ||
      this.isMedicalReportAdmin()
    );
  },
  isSystemAdmin() {
    return this.isSimpleMemberOf(SYSTEM_ADMINISTRATORS);
  },
  isSavedRepliesAdmin() {
    return this.isSimpleMemberOf(SAVED_REPLIES_ADMINISTRATORS);
  },
  isDrugsAdmin() {
    return this.isSimpleMemberOf(DRUGS_ADMINISTRATORS);
  },
  isMedicalReportAdmin() {
    return this.isSimpleMemberOf(MEDICAL_REPORT_ADMINISTRATORS);
  },
  isAgent() {
    return !this.isAdmin();
  },
  agentId() {
    return this.user?.id;
  },
  isMemberOf(groupName, agent) {
    let group = this.getGroupByName(groupName);
    if (!group) return false;
    if (group.addSystemAdministrators) {
      return this.isSystemAdmin();
    } else return this.isSimpleMemberOf(groupName, agent);
  },
  isSimpleMemberOf(groupName, agent) {
    let group = this.getGroupByName(groupName);
    return (group?.agentIds || []).indexOf((agent || this.user)?.id) !== -1;
  },
  getGroupByName(name) {
    return (this.groups || []).find(group => group.name === name);
  },
};
