<template>
  <main-layout>
    <template v-slot:title>
      <font-awesome-icon :icon="['fad', 'sliders-v-square']" class="mr-3" />
      {{ $t(`${i18nScope}.title`) }}
    </template>
    <config-form
      class="py-4 px-4"
      :config="config"
      :persist="onPersist"
      @change="onChange"
      v-if="config"
    />
  </main-layout>
</template>

<script>
import MainLayout from '@/components/admin/shared/main-layout';
import ConfigForm from '@/components/admin/config/form';

export default {
  name: 'EditConfig',
  components: { MainLayout, ConfigForm },
  data() {
    return { config: null, hasChanged: false };
  },
  mounted() {
    this.$bind('config', this.services.config.find()).then(
      ({ almostDrowning, drowning }) => {
        this.config = { almostDrowning, drowning, id: 42 }; // NOTE: id to force the model-form component to treat it as an update
      }
    );
  },
  computed: {
    i18nScope() {
      return 'admin.config.edit';
    },
  },
  methods: {
    onChange(changes) {
      this.config = this.services.config.applyChanges(this.config, changes);
      this.hasChanged = true;
    },
    onPersist() {
      if (!this.hasChanged) return new Promise(resolve => resolve(true));
      return this.services.config.update(this.config);
    },
  },
};
</script>
