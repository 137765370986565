<template>
  <div class="flex flex-wrap">
    <list-item
      v-for="(item, index) in list"
      :key="`medical-report-library-item-category-${topic.id}-${index}`"
      :item="item"
      :selected="isActive(item)"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import ListItem from './list-item';

export default {
  name: 'MedicalReportPickerList',
  components: { ListItem },
  props: {
    topic: Object,
    list: Array,
    selectedItems: { type: Array, required: true },
  },
  methods: {
    isActive(item) {
      return !!(this.selectedItems || []).find(
        localItem => localItem.id === item.id
      );
    },
  },
};
</script>
