export default {
  list() {
    return this.isSystemAdmin();
  },
  create() {
    return this.isSystemAdmin();
  },
  show() {
    return this.isSystemAdmin();
  },
  edit(agent) {
    return agent && this.isSystemAdmin();
  },
  showWithoutCurrentAgent(agent) {
    return agent.id !== this.agentId();
  },
};
