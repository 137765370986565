<template>
  <main-layout>
    <template v-slot:title>
      {{ $t('admin.brandedDrugs.new.title') }}
    </template>

    <branded-drug-form
      class="py-4 px-4"
      :brandedDrug="brandedDrug"
      :persist="onPersist"
      @change="onChange"
      v-if="brandedDrug"
    />
  </main-layout>
</template>

<script>
import { mapActions } from 'vuex';
import merge from 'deepmerge';
import MainLayout from '@/components/admin/shared/main-layout';
import BrandedDrugForm from './form';

export default {
  name: 'NewBrandedDrug',
  components: { MainLayout, BrandedDrugForm },
  data() {
    return { brandedDrug: null };
  },
  mounted() {
    this.brandedDrug = this.services.brandedDrug.build();
  },
  methods: {
    ...mapActions(['createBrandedDrug']),
    onChange(changes) {
      const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
      this.brandedDrug = merge(this.brandedDrug, changes, {
        arrayMerge: overwriteMerge,
      });
    },
    onPersist() {
      return this.createBrandedDrug(this.brandedDrug).then(brandedDrug => {
        this.$router.push({
          name: 'editBrandedDrug',
          params: { brandedDrugId: brandedDrug.id },
        });
      });
    },
  },
};
</script>
