<template>
  <div class="form-group mb-1">
    <label :for="name">{{ t('label') }}</label>
    <multiselect
      :id="name"
      :name="name"
      v-model="value"
      :multiple="true"
      :options="list"
      track-by="id"
      label="name"
      v-if="list"
      :placeholder="t('multiselect.placeholder')"
      :selectedLabel="t('multiselect.selectedLabel')"
      :selectLabel="t('multiselect.selectLabel')"
      :deselectLabel="t('multiselect.deselectLabel')"
    >
    </multiselect>
  </div>
</template>

<script>
import ModelInputMixin from '@/mixins/model-input';

export default {
  name: 'BrandedDrugMoleculesInput',
  mixins: [ModelInputMixin],
  data() {
    return {
      list: null,
    };
  },
  async mounted() {
    this.list = await this.services.molecule.findAll();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
