<template>
  <main-layout>
    <template v-slot:title>
      {{ $t('admin.agents.new.title') }}
    </template>

    <agent-form
      class="py-4 px-4"
      :agent="agent"
      :persist="onPersist"
      @change="onChange"
      v-if="agent && !createdWithSuccess"
    />

    <div v-if="agent && createdWithSuccess">
      <div class="bg-green-600 py-3">
        <div class="flex items-center justify-center flex-wrap">
          <h4 class="ml-3 font-medium text-white truncate">
            {{ $t('admin.agents.new.success.title') }}
          </h4>
        </div>
      </div>

      <div class="mt-8 px-4 text-center text-lg">
        <p class="text-xl font-semibold">
          {{ $t('admin.agents.new.success.body.first') }}
        </p>
        <p class="mt-4 text-gray-600">
          {{
            $t('admin.agents.new.success.body.second', {
              name: agent.firstName,
            })
          }}
          <br />
          <span class="font-bold">{{ resetPasswordUrl }}</span>
        </p>
        <p class="mt-4 text-gray-600">
          {{ $t('admin.agents.new.success.body.third') }}
        </p>
      </div>
    </div>
  </main-layout>
</template>

<script>
import merge from 'deepmerge';
import MainLayout from '@/components/admin/shared/main-layout';
import AgentForm from './form';
import { DESK_URL } from '@/shared/config';

export default {
  name: 'NewAgent',
  components: { MainLayout, AgentForm },
  data() {
    return {
      agent: null,
      createdWithSuccess: false,
      resetPasswordUrl: null,
    };
  },
  mounted() {
    this.agent = this.services.agent.build();
  },
  methods: {
    onChange(changes) {
      this.agent = merge(this.agent, changes);
    },
    onPersist() {
      const { newProfilePic, ...agent } = this.agent;
      return this.services.agent
        .create(agent, newProfilePic)
        .then(response =>
          this.complete(response.agentID, response.agentPasswordToken)
        );
    },
    complete(agentId, passwordToken) {
      // resetting the password happens in the Desk
      this.resetPasswordUrl = `${DESK_URL}reset-password/${agentId}/${passwordToken}`;
      // copy the url in the clipboard
      this.$copyText(this.resetPasswordUrl);
      // show the notification message
      this.createdWithSuccess = true;
    },
  },
};
</script>
