<template>
  <div>
    <div>
      <div
        v-for="libraryItem in list"
        :key="`${libraryItemId}-${libraryItem.id}`"
      >
        <div
          :class="{
            'hover:bg-gray-50': libraryItem.id !== libraryItemId,
            'border-l-2 border-blue-700 bg-indigo-50 hover:bg-indigo-100':
              libraryItem.id === libraryItemId,
          }"
        >
          <div
            class="cursor-pointer border-b border-gray-100"
            :style="{ paddingLeft: `${(libraryItem.depth + 2) * 20}px` }"
          >
            <item :libraryItem="libraryItem" :topic="topic" />
          </div>
        </div>

        <library-tree
          :topic="topic"
          :list="libraryItem.children"
          :libraryItemId="libraryItemId"
          v-if="!libraryItem.leaf"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Item from './item';

export default {
  name: 'LibraryTree',
  components: { Item },
  props: {
    topic: { type: Object },
    list: { type: Array, default: null },
    libraryItemId: { type: String, default: null },
  },
};
</script>
