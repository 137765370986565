<template>
  <sidebar-layout>
    <template v-slot:title>
      <font-awesome-icon :icon="['fad', 'pills']" class="mr-3" />
      <span>{{ $t('admin.brandedDrugs.list.title') }}</span>
    </template>

    <template v-slot:actions>
      <router-link
        :to="{ name: 'newBrandedDrug' }"
        class="btn-sm btn-outline-primary"
        :title="$t('admin.brandedDrugs.list.newButton')"
        v-tooltip="$t('admin.brandedDrugs.list.newButton')"
      >
        <font-awesome-icon :icon="['fas', 'plus']" />
      </router-link>
    </template>

    <div class="p-2 space-y-2 border-b border-gray-100">
      <search-input @search="onSearch" />
    </div>

    <list
      :brandedDrugId="brandedDrugId"
      :drugId="drugId"
      :list="filteredBrandedDrugs"
      v-if="hasBrandedDrugs"
    />
    <div v-else-if="query" class="px-4 py-5 font-semibold">
      {{ $t('admin.brandedDrugs.list.noBrandedDrug') }}
    </div>
  </sidebar-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SidebarLayout from '@/components/admin/shared/sidebar-layout';
import List from './list';

export default {
  name: 'BrandedDrugSidebar',
  components: { SidebarLayout, List },
  props: ['brandedDrugId', 'drugId'],
  data() {
    return {
      query: null,
    };
  },
  computed: {
    ...mapState(['brandedDrugs']),
    filteredBrandedDrugs() {
      return this.services.brandedDrug.search(this.brandedDrugs, this.query);
    },
    hasBrandedDrugs() {
      return !!this.brandedDrugs && this.filteredBrandedDrugs.length;
    },
  },
  methods: {
    ...mapActions(['listBrandedDrugs']),
    onSearch(query) {
      this.query = query;
    },
  },
  mounted() {
    this.listBrandedDrugs();
  },
};
</script>
