<template>
  <model-form
    :model="agent"
    i18nScope="admin.agents.form"
    :validate="services.agent.isValid"
    :on-submit="persist"
    v-on="$listeners"
  >
    <div class="grid grid-cols-2 gap-4">
      <text-input attributeName="firstName" />
      <text-input attributeName="lastName" />

      <select-input attributeName="gender">
        <option value="female">{{ $t('genders.female') }}</option>
        <option value="male">{{ $t('genders.male') }}</option>
      </select-input>

      <div class="col-span-1 row-span-2">
        <picture-input attributeName="newProfilePic" />
      </div>

      <job-title-input attributeName="titleKey" :sex="agent.gender" />
    </div>

    <hr class="my-8" />

    <div class="grid grid-cols-2 gap-4">
      <text-input attributeName="email" />
      <text-input attributeName="signInEmail" />
      <text-input attributeName="slackID" />
    </div>

    <hr class="my-8" />

    <tabs navClass="rounded-0">
      <tab
        v-for="(locale, index) in locales"
        :key="locale"
        :name="$t(`locales.${locale}`)"
        :selected="index === 0"
        v-on="$listeners"
      >
        <div class="grid grid-cols-2 gap-4 mt-4">
          <textarea-input
            attributeName="bio"
            :locale="locale"
            :rows="8"
            v-if="agent.bio"
          />
          <textarea-input
            attributeName="bioRD"
            :locale="locale"
            :rows="8"
            v-if="agent.bioRD"
          />
        </div>
      </tab>
    </tabs>

    <hr class="my-8" />

    <div class="grid grid-cols-2 gap-4">
      <text-input attributeName="professionalAddress" />
      <text-input attributeName="professionalPhone" />
      <text-input attributeName="adeli" />
      <text-input attributeName="rpps" />
      <picture-input attributeName="newSignatureUrl" />
    </div>

    <hr class="my-8" v-if="agent.notifications" />
    <nested-model-form
      class="grid grid-cols-2 gap-4"
      v-if="agent.notifications"
      v-model="agent.notifications"
      i18nScope="admin.agents.form.notifications"
    >
      <text-input attributeName="mobilePhone" />
      <checkbox-input attributeName="allowSMSReminders" />
    </nested-model-form>

    <hr class="my-8" />

    <div class="grid grid-cols-2 gap-4">
      <checkbox-input attributeName="isDisabled" />
    </div>
  </model-form>
</template>

<script>
export default {
  name: 'AgentForm',
  props: {
    agent: { type: Object },
    persist: { type: Function, default: () => {} },
  },
  computed: {
    locales() {
      return ['fr', 'en'];
    },
  },
};
</script>
