<template>
  <sidebar-layout>
    <template v-slot:title>
      <font-awesome-icon :icon="['fal', 'rocket']" class="mr-3" />
      <span>{{ $t('admin.savedReplies.list.title') }}</span>
    </template>

    <template v-slot:actions>
      <router-link
        :to="{ name: 'newSavedReply' }"
        class="btn-sm btn-outline-primary"
      >
        <font-awesome-icon :icon="['fas', 'plus']" />
      </router-link>
    </template>

    <div class="p-2 space-y-2 border-b border-gray-100">
      <search-input @search="onSearch" />
      <div class="flex justify-between items-center">
        <label class="text-gray-700" for="show-unpublished-saved-replies">
          {{ $t('admin.savedReplies.list.showUnpublished') }}
        </label>
        <custom-switch
          name="show-unpublished-saved-replies"
          @input="onShowUnpublished"
        />
      </div>
    </div>
    <saved-reply-tree
      v-if="list && tree.length"
      :savedReplyId="savedReplyId"
      :list="tree"
    />
    <div v-else-if="list && list.length" class="px-4 py-5 font-semibold">
      {{ $t('admin.savedReplies.list.noSavedReply') }}
    </div>
  </sidebar-layout>
</template>

<script>
import SidebarLayout from '@/components/admin/shared/sidebar-layout';
import SavedReplyTree from './tree';

export default {
  name: 'SavedRepliesList',
  components: { SidebarLayout, SavedReplyTree },
  props: ['savedReplyId'],
  data() {
    return {
      list: null,
      query: null,
      showUnpublished: false,
    };
  },
  mounted() {
    this.$bind(
      'list',
      this.services.savedReply.findAll(this.contentLocale, true)
    );
  },
  computed: {
    filters() {
      return {
        query: this.query,
        showUnpublished: this.showUnpublished,
      };
    },
    tree() {
      return this.services.savedReply.buildContentTree(
        this.list,
        this.contentLocale,
        this.filters
      );
    },
  },
  methods: {
    onSearch(query) {
      this.query = query;
    },
    onShowUnpublished(value) {
      this.showUnpublished = value;
    },
  },
};
</script>
