import merge from 'deepmerge';
import * as API from '@/shared/api';
import Fuse from 'fuse.js';
import { track as trackAgent } from './agent-tracking';

export const build = attributes => {
  return merge(
    {
      name: '',
      shortLabel: '',
      mobileLabel: '',
    },
    attributes || {}
  );
};

export const isValid = attributes => {
  return !!attributes.name && attributes.name.length > 0;
};

export const find = (list, id) => {
  if (!list) return; // list not loaded yet
  // NOTE: the API doesn't have a GET
  trackAgent('VIEW', 'MOLECULE', id);
  return list.find(molecule => molecule.id === id);
};

export const search = (list, query) => {
  if (!query || query.length === 0) return list;
  const fuse = new Fuse(list, {
    threshold: 0.1,
    keys: ['name'],
  });
  return fuse.search(query).map(result => ({ ...result.item }));
};

export const findAll = withTracking => {
  if (withTracking) trackAgent('VIEW', 'MOLECULES');
  return API.findAllMolecules().then(response => response.molecules);
};

export const create = async attributes => {
  trackAgent('CREATE', 'MOLECULE', attributes.name);
  return API.createMolecule(attributes);
};

export const update = async ({ id, ...attributes }) => {
  trackAgent('UPDATE', 'MOLECULE', id);
  return API.updateMolecule(id, attributes);
};
